import { Class } from "@/graphql";
import { CourseServiceApiClient } from "../clients/CourseServiceApiClient";
import { Page, ReferenceDataCategory } from "../types";
import { SearchSelectQuery, classOptionDTO } from "../user/types";
import {
  ClassQuery,
  FilterOptionAcademicLevelDTO,
  FilterOptionAcademicYearDTO,
  FilterOptionBranchDTO,
  FilterOptionClassDTO,
  FilterOptionCourseDTO,
  FilterOptionVenueDTO,
  ReferenceData,
} from "./types";

export class CourseApi {
  private static client = CourseServiceApiClient;

  private static coursePathPrefix = "/v1/courses";

  private static classPathPrefix = "/v1/classes";

  private static filterOptionPathPrefix = "/v1/filter-options";

  private static referenceDataPathPrefix = "/v1/references";

  public static async getClassOptionsByLevelId(
    levelId: string
  ): Promise<FilterOptionClassDTO[]> {
    const response = await this.client.get<null, FilterOptionClassDTO[]>(
      `${this.filterOptionPathPrefix}/classes/academic-level/${levelId}`
    );
    return response;
  }

  public static async getCourseOptionsByLevelId(
    levelId: string
  ): Promise<FilterOptionCourseDTO[]> {
    const response = await this.client.get<null, FilterOptionCourseDTO[]>(
      `${this.filterOptionPathPrefix}/courses/academic-level/${levelId}`
    );
    return response;
  }

  public static async getAcademicLevelOptions(): Promise<
    FilterOptionAcademicLevelDTO[]
  > {
    const response = await this.client.get<
      null,
      FilterOptionAcademicLevelDTO[]
    >(`${this.filterOptionPathPrefix}/academic-levels`);
    return response;
  }

  public static async getAcademicYearOptions(): Promise<
    FilterOptionAcademicYearDTO[]
  > {
    const response = await this.client.get<null, FilterOptionAcademicYearDTO[]>(
      `${this.filterOptionPathPrefix}/academic-years`
    );
    return response;
  }

  public static async getBranchOptions(): Promise<FilterOptionBranchDTO[]> {
    const response = await this.client.get<null, FilterOptionBranchDTO[]>(
      `${this.filterOptionPathPrefix}/branches`
    );
    return response;
  }

  public static async getVenueOptionsByBranchId(
    branchId: string
  ): Promise<FilterOptionVenueDTO[]> {
    const response = await this.client.get<null, FilterOptionVenueDTO[]>(
      `${this.filterOptionPathPrefix}/venues/branch/${branchId}`
    );
    return response;
  }

  public static async getVenueOptions(): Promise<FilterOptionVenueDTO[]> {
    const response = await this.client.get<null, FilterOptionVenueDTO[]>(
      `${this.filterOptionPathPrefix}/venues`
    );
    return response;
  }

  public static async getCourseOptions(): Promise<FilterOptionCourseDTO[]> {
    const response = await this.client.get<null, FilterOptionCourseDTO[]>(
      `${this.filterOptionPathPrefix}/courses`
    );
    return response;
  }

  public static async getCourseClasses(
    id: string,
    params: ClassQuery
  ): Promise<Class[]> {
    return this.client.post(`${this.coursePathPrefix}/${id}/classes`, {
      ...params,
    });
  }

  public static async toggleCourseVisibility(id: string): Promise<string[]> {
    return this.client.put(`${this.coursePathPrefix}/${id}/visibility`, {});
  }

  public static async bulkToggleClassVisibility(
    ids: string[]
  ): Promise<string[]> {
    return this.client.put(`${this.classPathPrefix}/bulk-visibility`, {
      ids,
    });
  }

  public static async getReferencesByCategory(
    category: ReferenceDataCategory
  ): Promise<ReferenceData[]> {
    return this.client.get(`${this.referenceDataPathPrefix}`, {
      category,
    });
  }

  public static async getClassOptions(
    query: SearchSelectQuery
  ): Promise<Page<classOptionDTO>> {
    return this.client.get(`${this.filterOptionPathPrefix}/classes`, query);
  }
}
