import { CourseApi } from "@/apis/course/course.api";
import { FilterOptionAcademicYearDTO } from "@/apis/course/types";
import { useRequest } from "ahooks";
import { useMemo } from "react";
import { OptionType } from "../types";

export const useAcademicYearOptions = (): {
  loading: boolean;
  options: OptionType[];
  academicYears: FilterOptionAcademicYearDTO[];
} => {
  const { data: academicYears = [], loading } = useRequest(
    () => CourseApi.getAcademicYearOptions(),
    {
      throwOnError: true,
      cacheKey: "academic-year-options",
      staleTime: 10000,
    }
  );

  const options = useMemo(
    () =>
      academicYears.map((i) => ({
        label: i.year.toString(),
        value: i._id,
      })),
    [academicYears]
  );

  return {
    loading,
    options,
    academicYears,
  };
};
