import { IMPORT_CLASSES_DATE_FORMAT } from "@/components/class/ImportClasses/Config";
import {
  CopyTaskListCommand,
  CreateTaskListCommand,
  GetTaskListQuery,
  UpdateTaskListCommand,
} from "@/graphql";
import { enumToArray } from "@/utils/enumUnit";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { WeekDay } from "@/utils/getWeeksOfYears";
import { graphqlValidator } from "@/utils/graphqlValidator";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  ModalButtonGroup,
  Row,
  Select,
  TimePicker,
  message,
} from "@thepiquelab/archus-components-web";
import { formatDateTime } from "@thepiquelab/archus-library";
import { InputNumber, Modal, Radio } from "antd";
import Input from "antd/lib/input/Input";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import {
  useCopyTaskList,
  useCreateTaskList,
  useUpdateTaskList,
} from "../hooks";

export enum ActionType {
  Add = "Add",
  Edit = "Edit",
  Copy = "Copy",
}

enum EndAtType {
  Never = "NEVER",
  On = "ON",
  After = "AFTER",
}

interface OperationalTaskListModalProps {
  visible?: boolean;
  taskListData?: GetTaskListQuery["taskList"];
  onCancel?: () => void;
  refetch?: () => void;
  actionType: ActionType;
}

const formatEndAt = (date: string): string =>
  date ? moment(date).endOf("day").toISOString() : null;

const OperationalTaskListModal: FC<OperationalTaskListModalProps> = (props) => {
  const { visible, taskListData, actionType, onCancel, refetch } = props;
  const [form] = Form.useForm();
  const [endAtType, setEndAtType] = useState<EndAtType>(EndAtType.Never);
  const isAutoGenerate = Form.useWatch("isAutoGenerate", form);
  const startAt = Form.useWatch("startAt", form);
  const weekDay = Form.useWatch("weekDay", form);
  const generateAt = Form.useWatch("generateAt", form);
  const [createTaskList, { loading: createLoading }] = useCreateTaskList();
  const [updateTaskList, { loading: updateLoading }] = useUpdateTaskList();
  const [copyTaskList, { loading: copyLoading }] = useCopyTaskList();

  const formatInitialValue = (
    taskList: GetTaskListQuery["taskList"]
  ): Partial<CreateTaskListCommand> => {
    const { parent } = taskList || {};
    const initData = parent?.id ? parent : taskList;
    let initEndAtType = EndAtType.Never;
    if (initData?.endAt) {
      initEndAtType = EndAtType.On;
    } else if (initData.occurrences) {
      initEndAtType = EndAtType.After;
    }
    setEndAtType(initEndAtType);

    return {
      name: taskList?.name,
      isAutoGenerate: initData?.isAutoGenerate,
      // startAt: initData?.startAt ? moment(initData?.startAt) : undefined,
      endAt: initData?.endAt ? moment(initData?.endAt) : undefined,
      generateAt: initData?.generateAt
        ? moment(initData?.generateAt)
        : undefined,
      weekDay: initData?.weekDay,
      occurrences: initData?.occurrences ?? undefined,
    };
  };

  const handleFinish = (value: CreateTaskListCommand): void => {
    if (actionType === ActionType.Edit && taskListData?.id) {
      handleUpdateTaskList({ ...value, id: taskListData.id });
    }
    if (actionType === ActionType.Add) {
      handleCreateTaskList(value);
    }
    if (actionType === ActionType.Copy) {
      handleCopyTaskList({ ...value, listId: taskListData.id });
    }
  };

  const handleCreateTaskList = async (
    input: CreateTaskListCommand
  ): Promise<void> => {
    const { endAt: endDate, occurrences, ...res } = input;
    try {
      await createTaskList({
        variables: {
          command: {
            ...res,
            occurrences: occurrences || null,
            isAutoGenerate: res?.isAutoGenerate ? res?.isAutoGenerate : false,
            endAt: formatEndAt(endDate),
          },
        },
      });
      message.success("List added successfully!");
      refetch?.();
      handleClose();
    } catch (error) {
      if (error?.graphQLErrors) {
        graphqlValidator(error?.graphQLErrors, form);
      } else {
        message.error(formatErrorMessage(error));
      }
    }
  };

  const handleCopyTaskList = async (
    input: CopyTaskListCommand
  ): Promise<void> => {
    const { endAt: endDate, ...res } = input;
    try {
      await copyTaskList({
        variables: {
          command: {
            ...res,
            isAutoGenerate: res?.isAutoGenerate ? res?.isAutoGenerate : false,
            endAt: formatEndAt(endDate),
          },
        },
      });
      message.success("List added successfully!");
      refetch?.();
      handleClose();
    } catch (error) {
      if (error?.graphQLErrors) {
        graphqlValidator(error?.graphQLErrors, form);
      } else {
        message.error(formatErrorMessage(error));
      }
    }
  };

  const handleUpdateTaskList = async (
    input: UpdateTaskListCommand
  ): Promise<void> => {
    const { endAt: endDate, name, occurrences, ...res } = input;
    const { parent } = taskListData || {};

    const commonInput = {
      isAutoGenerate: res?.isAutoGenerate ? res?.isAutoGenerate : false,
      endAt: formatEndAt(endDate),
      occurrences: occurrences || null,
    };

    const defaultInput: Omit<UpdateTaskListCommand, "id"> = {
      isAutoGenerate: false,
      endAt: null,
      generateAt: null,
      weekDay: null,
      occurrences: null,
    };

    let currentInput: UpdateTaskListCommand = {
      ...res,
      name,
      ...commonInput,
    };

    try {
      if (parent?.id) {
        currentInput = { name, id: res?.id };

        await updateTaskList({
          variables: {
            command: !res?.isAutoGenerate
              ? { id: parent.id, ...defaultInput }
              : { ...res, id: parent.id, ...commonInput },
          },
        });
      }

      await updateTaskList({
        variables: {
          command: !res?.isAutoGenerate
            ? { name, id: res?.id, ...defaultInput }
            : currentInput,
        },
      });

      refetch?.();
      handleClose();
    } catch (error) {
      if (error?.graphQLErrors) {
        graphqlValidator(error?.graphQLErrors, form);
      } else {
        message.error(formatErrorMessage(error));
      }
    }
  };

  const handleClose = (): void => {
    form?.resetFields();
    onCancel?.();
  };

  useEffect(() => {
    if (taskListData) {
      form.setFieldsValue(formatInitialValue(taskListData));
    }
  }, [taskListData]);

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      destroyOnClose
      title={actionType === ActionType.Edit ? "Edit List" : "Add List"}
      footer={
        <ModalButtonGroup
          okText={actionType === ActionType.Edit ? "Save" : "Add"}
          cancelText="Cancel"
          onOK={form.submit}
          onCancel={handleClose}
          confirmLoading={createLoading || updateLoading || copyLoading}
        />
      }
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name={"name"}
          label="List Name"
          rules={[{ required: true, message: "Field is required." }]}
        >
          <Input maxLength={200} />
        </Form.Item>
        <Form.Item name="isAutoGenerate" label={null} valuePropName="checked">
          <Checkbox>Generate Weekly</Checkbox>
        </Form.Item>
        {isAutoGenerate ? (
          <>
            <Form.Item label={formatLabel("Ends On")} required>
              <Radio.Group
                className="flex flex-col gap-4"
                value={endAtType}
                onChange={(e) => {
                  const type = e.target.value;
                  switch (type) {
                    case EndAtType.Never:
                      form.resetFields(["endAt", "occurrences"]);
                      break;
                    case EndAtType.On:
                      form.resetFields(["occurrences"]);
                      break;
                    case EndAtType.After:
                      form.resetFields(["endAt"]);
                      break;

                    default:
                      break;
                  }
                  setEndAtType(type);
                }}
              >
                <Radio value={EndAtType.Never}>Never</Radio>
                <Radio value={EndAtType.On}>
                  <div className="flex justify-between items-center">
                    <span>On</span>
                    <Form.Item
                      noStyle
                      name={"endAt"}
                      rules={[
                        {
                          required: endAtType === EndAtType.On,
                          message: "Field is required.",
                        },
                      ]}
                    >
                      <DatePicker
                        className="w-56"
                        disabled={endAtType !== EndAtType.On}
                        format={IMPORT_CLASSES_DATE_FORMAT}
                      />
                    </Form.Item>
                  </div>
                </Radio>
                <Radio value={EndAtType.After}>
                  <div className="flex justify-between items-center">
                    <span>After</span>
                    <Form.Item
                      noStyle
                      name="occurrences"
                      rules={[
                        {
                          required: endAtType === EndAtType.After,
                          message: "Field is required.",
                        },
                      ]}
                    >
                      <InputNumber
                        className="w-56"
                        disabled={endAtType !== EndAtType.After}
                        placeholder={formatLabel("occurrences")}
                        min={1}
                      />
                    </Form.Item>
                  </div>
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Repeat on"
              className="no-margin"
              requiredMark
              required
            >
              <Row className="w-full">
                <Col span={11}>
                  <Form.Item
                    name={"weekDay"}
                    className="no-margin"
                    rules={[{ required: true, message: "Field is required." }]}
                  >
                    <Select
                      allowClear
                      getPopupContainer={(triggerNode: any) =>
                        triggerNode.parentNode
                      }
                    >
                      {enumToArray(WeekDay).map((i) => (
                        <Select.Option key={i.key} value={i.value}>
                          {i.key}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={11} offset={2}>
                  <Form.Item
                    name={"generateAt"}
                    className="no-margin"
                    rules={[{ required: true, message: "Field is required." }]}
                  >
                    <TimePicker
                      use12Hours
                      hourStep={1}
                      format="hA"
                      className="w-full"
                      getPopupContainer={(triggerNode: any) =>
                        triggerNode.parentNode
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            {taskListData?.startAt && weekDay && generateAt ? (
              <div className="mt-6">
                {`Repeat every ${weekDay} starting on ${formatDateTime(
                  taskListData?.startAt
                )}`}
              </div>
            ) : null}
          </>
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
};

export default OperationalTaskListModal;
