import { calcCapacitySeatsLeft } from "@/components/class/NewClassCapacity/classUtils";
import CollapseList from "@/components/common/CollapseList/CollapseList";
import { QueryInputs } from "@/components/common/EnhancedTable/EnhancedTable";
import { useAcademicYearOptions } from "@/components/widget/FilterFormOption/hooks/useAcademicYearOptions";
import {
  ClassFilterInput,
  GetWaitingListOverviewClasses,
  GetWaitingListOverviewClassesQuery,
  GetWaitingListOverviewClassesQueryVariables,
} from "@/graphql";
import { useLoadMore } from "@/hooks/useLoadMore";
import { SearchOutlined } from "@ant-design/icons";
import { Container, Input } from "@thepiquelab/archus-components-web";
import Select from "antd/es/select";
import { debounce } from "lodash";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import CollapseTable from "./CollapseTable";
import { filterCompletedWorkshop } from "./utils";

const ClassWaitingList: FC = () => {
  const [queryClasses, { data: classesData, loading }, { loadMore, hasMore }] =
    useLoadMore<
      GetWaitingListOverviewClassesQuery,
      GetWaitingListOverviewClassesQueryVariables
    >(GetWaitingListOverviewClasses, "classesWithIndex", true, false, true, {
      fetchPolicy: "network-only",
    });

  const [query, setQuery] = useState<QueryInputs<ClassFilterInput>>({
    filterInput: { isFull: null, isWaitlisted: true },
  });

  const { options: academicYearOptions, academicYears } =
    useAcademicYearOptions();

  useEffect(() => {
    if (academicYearOptions?.length) {
      const year = academicYears?.find((i) => i?.year === moment().year());
      if (year?._id) {
        setQuery({
          ...query,
          filterInput: {
            ...(query?.filterInput || {}),
            academicYearIds: [year?._id],
          },
        });
      }
    }
  }, [academicYearOptions]);

  useEffect(() => {
    if (query?.filterInput && Object.keys(query?.filterInput)?.length) {
      queryClasses({
        variables: {
          ...query,
          filterInput: {
            ...query?.filterInput,
            isFull:
              query?.filterInput?.isFull === null
                ? undefined
                : query?.filterInput?.isFull,
          },
        },
      });
    }
  }, [query]);

  const handleSearch = (text: string): void => {
    setQuery({
      ...query,
      filterInput: {
        ...(query?.filterInput || {}),
        name: text,
      },
    });
  };

  return (
    <Container>
      <Container.Header className="my-6 flex items-center">
        <Input
          allowClear
          size="large"
          className="search-input max-w-72"
          placeholder="Search"
          prefix={<SearchOutlined className="text-primary-navyLight text-5" />}
          onChange={debounce((e) => {
            handleSearch(e.target.value);
          }, 300)}
          onPressEnter={debounce((e) => {
            handleSearch(e.target.value);
          }, 100)}
          data-testid="grid-search-input"
        />

        <Select
          className="w-48 ml-2"
          size="small"
          options={[
            { label: "All Availability", value: null },
            { label: "Available Class", value: false },
            { label: "Unavailable Class", value: true },
          ]}
          value={query?.filterInput?.isFull}
          onChange={(value: boolean) => {
            setQuery({
              ...query,
              filterInput: {
                ...(query?.filterInput || {}),
                isFull: value,
              },
            });
          }}
        />

        <Select
          className="w-28 ml-2"
          size="small"
          value={query?.filterInput?.academicYearIds?.[0]}
          onChange={(value) => {
            setQuery({
              ...query,
              filterInput: {
                ...(query?.filterInput || {}),
                academicYearIds: [value],
              },
            });
          }}
          options={academicYearOptions}
        />
      </Container.Header>
      <Container.Content className="h-full overflow-y-auto">
        <CollapseList
          dataSource={classesData?.classesWithIndex?.items.filter(
            filterCompletedWorkshop
          )}
          loadMore={loadMore}
          hasMore={hasMore}
          loading={loading}
          renderItem={(item) => (
            <CollapseTable
              key={`collapse-table-${item?.id}`}
              classData={item}
              className="mb-6"
              seatsLeft={calcCapacitySeatsLeft(item?.capacity)}
            />
          )}
        />
      </Container.Content>
    </Container>
  );
};

export default ClassWaitingList;
