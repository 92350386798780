import { ReactComponent as IconCompleteTask } from "@/assets/images/IconCheck.svg";
import { ReactComponent as IconReopen } from "@/assets/images/IconRefresh.svg";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import CommonBadge from "@/components/widget/Badge/CommonBadge";
import {
  TaskFieldsFragment,
  TaskStatus,
  UpdateTaskInput,
  WeekDay,
} from "@/graphql";
import { enumToArray, enumToLabelArray } from "@/utils/enumUnit";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Select,
  Skeleton,
  message,
} from "antd";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import AssigneeSelect from "../Checklist/AssigneeSelect";
import {
  useCompleteTasks,
  useReopenTask,
  useUpdateFollowTaskDueDate,
  useUpdateTask,
} from "../hooks";
import { TaskStatusTagMap } from "../utils";
import AssigneeAvatars from "./AssigneeAvatars";

interface TaskDetailProps {
  taskDetail: TaskFieldsFragment;
  callback?: () => Promise<void> | void;
  refetchTask: () => void;
  refetchTaskLoading: boolean;
  onClose?: () => void;
}

const TaskDetail: React.FC<TaskDetailProps> = (props) => {
  const { taskDetail, callback, refetchTask, refetchTaskLoading, onClose } =
    props;

  const isReadonly = [TaskStatus.Complete, TaskStatus.Void].includes(
    taskDetail?.status
  );

  const [attributeEditMap, setAttributeEditMap] = useState<{
    [propName: string]: boolean;
  }>({});
  const [updateTask, { data, loading: updateTaskLoading }] = useUpdateTask();
  const [updateDueDate, { loading: updateDueDateLoading }] =
    useUpdateFollowTaskDueDate();

  const { modal, show, close, setModalProps } = useConfirmModal();

  const [reopenTask, { loading: reopenLoading }] = useReopenTask();

  const [completeTasks, { loading: completeLoading }] = useCompleteTasks();
  const [form] = Form.useForm();

  const loading =
    updateTaskLoading || refetchTaskLoading || updateDueDateLoading;

  const formCol = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const handleButtonGroupClicked = async (
    buttonKey: string,
    attributeName: string
  ): Promise<void> => {
    if (buttonKey === "edit") {
      setAttributeEditMap({
        ...attributeEditMap,
        [attributeName]: true,
      });
    } else if (buttonKey === "cancel") {
      setAttributeEditMap({
        ...attributeEditMap,
        [attributeName]: false,
      });
      let weekDay: WeekDay;
      enumToArray(WeekDay).forEach((d) => {
        if (taskDetail.name.startsWith(d.value)) {
          weekDay = d.value as WeekDay;
        }
      });
      form.setFieldsValue({
        weekDay,
        name: taskDetail?.name.replace(weekDay, ""),
        dueDate: taskDetail?.dueDate ? moment(taskDetail?.dueDate) : null,
        assignee: taskDetail?.assignees?.map((assignee) => assignee?._id),
        description: taskDetail?.description,
      });
    } else if (buttonKey === "save") {
      const variables: Partial<UpdateTaskInput> = {};
      if (attributeName === "name") {
        variables.name = `${form.getFieldValue("weekDay")}${
          form.getFieldValue("name") || ""
        }`;
      }
      if (attributeName === "dueDate") {
        try {
          await updateDueDate({
            variables: {
              id: taskDetail?.id,
              dueDate: moment(form.getFieldValue("dueDate"))
                ? moment(form.getFieldValue("dueDate")).toISOString()
                : null,
            },
          });
          setAttributeEditMap({
            ...attributeEditMap,
            [attributeName]: false,
          });
          await refetchTask();
          callback?.();
        } catch (error) {
          message.error(formatErrorMessage(error));
        }
        return;
      }
      if (attributeName === "assignee") {
        variables.assigneeIds = form.getFieldValue("assignee");
      }
      if (attributeName === "description") {
        variables.description = form.getFieldValue("description") || null;
      }
      try {
        await updateTask({
          variables: {
            input: {
              ...variables,
              id: taskDetail?.id,
            },
          },
        });
        setAttributeEditMap({
          ...attributeEditMap,
          [attributeName]: false,
        });
        await refetchTask();
        callback?.();
      } catch (error) {
        message.error(formatErrorMessage(error));
      }
    }
  };
  const renderButtons = (attributeName: string): ReactNode => {
    if (isReadonly) return <></>;
    const isEditing = attributeEditMap[attributeName];
    const isLoading = loading && isEditing;

    return (
      <div className="flex gap-2 items-center justify-between">
        {isEditing && (
          <>
            <Button
              key="cancel"
              onClick={() => {
                handleButtonGroupClicked("cancel", attributeName);
              }}
            >
              Cancel
            </Button>
            <Button
              key="save"
              onClick={() => {
                handleButtonGroupClicked("save", attributeName);
              }}
              type="primary"
              loading={isLoading}
            >
              Save
            </Button>
          </>
        )}
        {!isEditing && (
          <Button
            key="edit"
            onClick={() => {
              handleButtonGroupClicked("edit", attributeName);
            }}
            loading={isLoading}
          >
            Edit
          </Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (taskDetail) {
      let weekDay: WeekDay;
      enumToArray(WeekDay).forEach((d) => {
        if (taskDetail.name.startsWith(d.value)) {
          weekDay = d.value as WeekDay;
        }
      });

      form.setFieldsValue({
        weekDay,
        name: taskDetail?.name.replace(weekDay, ""),
        dueDate: taskDetail?.dueDate ? moment(taskDetail?.dueDate) : null,
        assignee: taskDetail?.assignees?.map(
          (assigneeItem) => assigneeItem?._id
        ),
        description: taskDetail?.description,
        isTemplate: taskDetail?.isTemplate,
      });
    }
  }, [taskDetail]);

  const handleReopenTask = async (): Promise<void> => {
    try {
      setModalProps({ confirmLoading: true });
      await reopenTask({ variables: { id: taskDetail?.id } });
    } catch (error) {
      message.error(formatErrorMessage(error));
    } finally {
      close();
      await refetchTask();
      setModalProps({ confirmLoading: false });
      callback?.();
      onClose?.();
    }
  };

  const handleMarkAsTemplate = async (checked: boolean): Promise<void> => {
    try {
      setAttributeEditMap({
        ...attributeEditMap,
        isTemplate: true,
      });
      await updateTask({
        variables: {
          input: {
            isTemplate: checked,
            id: taskDetail?.id,
          },
        },
      });
    } catch (e) {
      message.error(formatErrorMessage(e));
    } finally {
      refetchTask();
      setAttributeEditMap({
        ...attributeEditMap,
        isTemplate: false,
      });
      callback?.();
    }
  };

  return (
    <>
      <Form form={form} layout="vertical" name="task-detail-form">
        <div className="flex justify-between mb-6">
          <div className="flex gap-2">
            {loading && attributeEditMap.isTemplate ? (
              <Skeleton.Button />
            ) : (
              <Form.Item
                name="isTemplate"
                valuePropName="checked"
                className="flex items-center justify-items-center no-margin"
              >
                <Checkbox
                  onChange={(e) => handleMarkAsTemplate(e.target.checked)}
                >
                  Make Template
                </Checkbox>
              </Form.Item>
            )}
          </div>
          <div className="">
            {taskDetail?.status === TaskStatus.Complete ? (
              <Button
                loading={reopenLoading}
                icon={<IconReopen className="w-5 h-4 mr-1" />}
                onClick={() => {
                  setModalProps({
                    onConfirm: handleReopenTask,
                    onClose: close,
                  });
                  show();
                }}
              >
                Reopen Task
              </Button>
            ) : (
              <Button
                icon={
                  <span className="anticon anticon-printer">
                    <IconCompleteTask className="w-5 h-4" />
                  </span>
                }
                disabled={isReadonly}
                loading={completeLoading}
                onClick={() => {
                  setModalProps({
                    onConfirm: async () => {
                      try {
                        setModalProps({ confirmLoading: true });
                        await completeTasks({
                          variables: {
                            command: {
                              type: taskDetail.type,
                              ids: [taskDetail?.id],
                            },
                          },
                        });
                      } catch (e) {
                        message.error(formatErrorMessage(e));
                      } finally {
                        close();
                        await refetchTask();
                        setModalProps({ confirmLoading: false });
                        callback?.();
                        onClose?.();
                      }
                    },
                    onClose: close,
                  });
                  show();
                }}
              >
                Mark As Complete
              </Button>
            )}
          </div>
        </div>
        <Form.Item label={"Task Name"} required>
          <div className="flex items-center justify-between gap-x-6">
            {attributeEditMap.name && !isReadonly ? (
              <Input.Group compact className="flex-1">
                <Form.Item
                  noStyle
                  name="weekDay"
                  rules={[
                    {
                      required: true,
                      message: formatLabel("Field is required."),
                    },
                  ]}
                  labelCol={{
                    span: 24,
                  }}
                >
                  <Select
                    options={enumToLabelArray(WeekDay).map((item) => ({
                      ...item,
                      value: item.value,
                    }))}
                    dropdownMatchSelectWidth={false}
                    style={{ minWidth: "28%" }}
                  />
                </Form.Item>
                <Form.Item
                  noStyle
                  name="name"
                  labelCol={{
                    span: 24,
                  }}
                >
                  <Input style={{ width: "72%" }} />
                </Form.Item>
              </Input.Group>
            ) : (
              <div>{taskDetail?.name}</div>
            )}
            {renderButtons("name")}
          </div>
        </Form.Item>
        <Form.Item label={"Task Due At"}>
          <div className="flex items-center justify-between gap-x-6">
            {attributeEditMap.dueDate && !isReadonly ? (
              <Form.Item noStyle name="dueDate" {...formCol} className="flex-1">
                <DatePicker
                  className="w-full"
                  allowClear
                  format={"DD/MM/YYYY"}
                />
              </Form.Item>
            ) : (
              <>
                {taskDetail?.dueDate
                  ? moment(taskDetail?.dueDate).format("DD/MM/YYYY")
                  : null}
              </>
            )}
            {renderButtons("dueDate")}
          </div>
        </Form.Item>
        <Form.Item label="Task Status">
          <CommonBadge
            textColor={TaskStatusTagMap[taskDetail?.status]?.textColor}
            bgColor={TaskStatusTagMap[taskDetail?.status]?.bgColor}
            label={taskDetail?.status}
          />
        </Form.Item>
        <Form.Item label={"Assignee"}>
          <div className="flex items-center justify-between gap-x-6">
            {attributeEditMap.assignee && !isReadonly ? (
              <Form.Item name="assignee" noStyle className="flex-1">
                <AssigneeSelect />
              </Form.Item>
            ) : (
              <div className="flex-1">
                {taskDetail?.assignees?.length > 0 ? (
                  <AssigneeAvatars assignees={taskDetail?.assignees} />
                ) : (
                  <></>
                )}
              </div>
            )}
            {renderButtons("assignee")}
          </div>
        </Form.Item>
        <Form.Item label="Description">
          <div className="flex items-center justify-between gap-x-6">
            {attributeEditMap.description && !isReadonly ? (
              <Form.Item
                name="description"
                noStyle
                rules={[
                  {
                    min: 1,
                    message: formatLabel("Field is required."),
                  },
                ]}
              >
                <Input.TextArea className="w-full" />
              </Form.Item>
            ) : (
              <div>{taskDetail?.description}</div>
            )}
            {renderButtons("description")}
          </div>
        </Form.Item>
      </Form>
      {modal}
    </>
  );
};

export default TaskDetail;
