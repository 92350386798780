import { TelephoneType } from "@/components/common/Profile/types";
import qs from "qs";
import { CoreServiceApiClient } from "../clients/CoreServiceApiClient";
import { UserServiceApiClient } from "../clients/UserServiceApiClient";
import { GetRequestsFilterInput, UserRequest } from "../enrollment/types";
import { Page, PageQuery } from "../types";
import {
  FilterOptionSystemUserDTO,
  FilterOptionTeacherDTO,
  SearchSelectQuery,
  StudentOptionDTO,
  UpdateRequestDetailDTO,
  UserRole,
  UserRoleFilter,
} from "./types";

export class UserApi {
  private static client = UserServiceApiClient;

  private static coreServiceClient = CoreServiceApiClient;

  private static userPathPrefix = "/v1/system-users";

  private static rolePathPrefix = "/v1/user-roles";

  private static filterOptionPathPrefix = "/v1/filter-options";

  private static accountRequestsPathPrefix = "/v1/account-requests";

  public static async me(): Promise<any> {
    return this.client.get<any, any>(`${this.userPathPrefix}/me`);
  }

  public static async syncRole(): Promise<void> {
    return this.client.put(`${this.userPathPrefix}/me/sync-role`);
  }

  public static async isMobileUnique(data: {
    mobilePhone: TelephoneType;
    id?: string;
  }): Promise<any> {
    return this.coreServiceClient.post<
      {
        mobilePhone: TelephoneType;
        id?: string;
      },
      any
    >("/isMobilePhoneUnique", data);
  }

  public static async isUserIdExists(data: {
    userId: string;
    alternateUserId?: string;
    id?: string;
  }): Promise<any> {
    return this.coreServiceClient.post<
      {
        userId: string;
        alternateUserId?: string;
        id?: string;
      },
      any
    >("/isUserIdExists", data);
  }

  public static async getRoles(
    filter: UserRoleFilter = {},
    pageQuery: PageQuery = {}
  ): Promise<Page<UserRole>> {
    const { name } = filter;
    return this.client.get(`${this.rolePathPrefix}`, {
      name,
      ...pageQuery,
    });
  }

  public static async getTeacherOptions(): Promise<FilterOptionTeacherDTO[]> {
    const response = await this.client.get<null, FilterOptionTeacherDTO[]>(
      `${this.filterOptionPathPrefix}/teachers`
    );
    return response;
  }

  public static async getWithoutTeacherOptions(): Promise<
    FilterOptionSystemUserDTO[]
  > {
    const response = await this.client.get<null, FilterOptionSystemUserDTO[]>(
      `${this.filterOptionPathPrefix}/without-teachers`
    );
    return response;
  }

  public static async getAccountRequestListApi(params?: {
    query?: GetRequestsFilterInput;
    pageQuery?: PageQuery;
    types?: any[];
  }): Promise<UserRequest[]> {
    const query = params?.query || {};
    const pageQuery = params?.pageQuery || {};

    const queryString = qs.stringify(pageQuery)
      ? `?${qs.stringify(pageQuery)}`
      : "";
    const response = await this.client.post(
      `${this.accountRequestsPathPrefix}/list${queryString}`,
      { ...query, types: params?.types }
    );

    return response as any;
  }

  public static async getAccountRequestDetailsApi(
    id: string
  ): Promise<UpdateRequestDetailDTO> {
    return this.client.get<null, UpdateRequestDetailDTO>(
      `${this.accountRequestsPathPrefix}/${id}/details`
    );
  }

  public static async getAccountRequestApproveApi(id: string) {
    return this.client.put(`${this.accountRequestsPathPrefix}/${id}/approve`);
  }

  public static async getAccountRequestRejectApi(
    id: string,
    params: any
  ): Promise<FilterOptionSystemUserDTO[]> {
    return this.client.put<null, FilterOptionSystemUserDTO[]>(
      `${this.accountRequestsPathPrefix}/${id}/reject`,
      params
    );
  }

  public static async getStudentsOptions(
    query: SearchSelectQuery
  ): Promise<Page<StudentOptionDTO>> {
    return this.client.get(`${this.filterOptionPathPrefix}/students`, query);
  }
}
