import { ReactComponent as IconDelete } from "@/assets/images/IconDelete.svg";
import { ReactComponent as IconEdit } from "@/assets/images/IconEdit.svg";
import { CreateTaskInput } from "@/graphql";
import { enumToLabelArray } from "@/utils/enumUnit";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { WeekDay } from "@thepiquelab/archus-library/dist/utils/mockUtils";
import { Collapse, Form, Input, Modal, Select, Spin, message } from "antd";
import { pick } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useCreateTask, useQueryAllTasks, useUpdateTask } from "../hooks";

interface TaskCreateModalProps {
  listId: string;
  title?: string;
  callback?: () => Promise<void> | void;
  onClose: () => void;
  openTaskDetail?: (id: string) => void;
}

const TaskCreateModal: React.FC<TaskCreateModalProps> = (props) => {
  const {
    title = "Add Task",
    listId,
    callback,
    onClose,
    openTaskDetail,
  } = props;
  const [createTask, { loading: createTaskLoading }] = useCreateTask();

  const [form] = Form.useForm();

  const [
    queryAllTasks,
    {
      data: templateTasks,
      loading: queryTemplateLoading,
      refetch: refetchAllTasks,
    },
  ] = useQueryAllTasks({
    fetchPolicy: "network-only",
  });

  const [updateTask, { loading: updateTaskLoading }] = useUpdateTask();

  const [taskIdsToUnmakeTemplate, setTaskIdsToUnmakeTemplate] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (listId) {
      queryTasksWithTemplate();
    }
  }, [listId]);

  const templates = useMemo(() => {
    if (templateTasks?.tasks?.items?.length) {
      return templateTasks?.tasks?.items?.map((task) => ({
        value: task?.id,
        label: task?.name,
      }));
    }
    return [];
  }, [templateTasks]);

  const queryTasksWithTemplate = (): void => {
    queryAllTasks({
      variables: {
        filterInput: {
          isTemplate: true,
        },
        pageInfo: {
          pageIndex: 0,
          pageSize: 0,
        },
      },
    });
  };

  const addTemplateTask = async (value: any) => {
    const selectedTask = templateTasks?.tasks?.items?.find(
      (task) => task.id === value?.templateId
    );
    const createInput: CreateTaskInput = {
      name: selectedTask.name,
      listId,
    };

    createInput.checklists = selectedTask?.checklists.map((checklist) => {
      const newChecklist = pick(checklist, ["name", "assigneeIds", "items"]);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newChecklist.items =
        newChecklist?.items?.map((item) =>
          pick(item, ["name", "assigneeIds"])
        ) || [];
      return newChecklist;
    });

    createInput.assigneeIds = selectedTask?.assignees?.map(
      (assignee) => assignee?._id
    );

    try {
      await createTask({
        variables: {
          input: createInput,
        },
      });

      message.success("Task added successfully!");
      onCloseModal();
      await callback?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const addTask = async (value: any) => {
    try {
      await createTask({
        variables: {
          input: {
            name: `${value?.weekDay || ""}${value?.name || ""}`,
            listId,
          },
        },
      });
      message.success("Task added successfully!");
      onCloseModal();
      await callback?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const onCloseModal = () => {
    form.resetFields();
    onClose();
  };
  return (
    <>
      <Modal
        title={title}
        onCancel={onCloseModal}
        onOk={() => {
          form.submit();
        }}
        okText="Add"
        visible={!!listId}
        okButtonProps={{
          loading: createTaskLoading,
        }}
        destroyOnClose
        bodyStyle={{ padding: "12px 8px" }}
      >
        <Form
          onFinish={(values) => {
            console.log("%c Line:159 🥓 values", "color:#b03734", values);
            if (values.templateId) {
              addTemplateTask(values);
            } else {
              addTask(values);
            }
          }}
          colon={false}
          form={form}
        >
          <Collapse
            accordion
            ghost
            expandIconPosition="end"
            destroyInactivePanel
            defaultActiveKey={["templateId"]}
            onChange={(key) => {
              if (key === "templateId") {
                form.resetFields(["name"]);
              } else {
                form.resetFields(["templateId"]);
              }
            }}
          >
            <Collapse.Panel
              header={
                <span className="font-semibold">Select From Template</span>
              }
              key="templateId"
            >
              <Form.Item
                name="templateId"
                className="w-full no-margin"
                rules={[
                  {
                    required: true,
                    message: formatLabel("Field is required."),
                  },
                ]}
                labelCol={{
                  span: 24,
                }}
              >
                <Select
                  dropdownMatchSelectWidth={false}
                  style={{ minWidth: 200 }}
                  optionLabelProp="label"
                >
                  {templates?.map((i) => (
                    <Select.Option
                      className="flex  flex-no-wrap"
                      value={i?.value}
                      key={i?.value}
                      label={i.label}
                    >
                      <div className="flex justify-between  items-center flex-no-wrap">
                        {i.label}

                        <Spin
                          spinning={
                            updateTaskLoading &&
                            taskIdsToUnmakeTemplate?.includes(i?.value)
                          }
                        >
                          <div className="flex justify-between gap-2  items-center flex-no-wrap">
                            <IconEdit
                              onClick={(e) => {
                                e.stopPropagation();
                                onCloseModal();
                                openTaskDetail?.(i.value);
                              }}
                            />

                            <IconDelete
                              onClick={async (e) => {
                                e.stopPropagation();
                                setTaskIdsToUnmakeTemplate([
                                  ...taskIdsToUnmakeTemplate,
                                  i.value,
                                ]);
                                try {
                                  await updateTask({
                                    variables: {
                                      input: {
                                        isTemplate: false,
                                        id: i?.value,
                                      },
                                    },
                                  });
                                  setTaskIdsToUnmakeTemplate(
                                    taskIdsToUnmakeTemplate?.filter(
                                      (id) => id !== i.value
                                    )
                                  );
                                  message.success(
                                    "Template Removed Successfully."
                                  );
                                  await refetchAllTasks?.();
                                  await callback?.();
                                } catch (error) {
                                  setTaskIdsToUnmakeTemplate(
                                    taskIdsToUnmakeTemplate?.filter(
                                      (id) => id !== i.value
                                    )
                                  );
                                  message.error(formatErrorMessage(error));
                                }
                              }}
                            />
                          </div>
                        </Spin>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel
              header={<span className="font-semibold">Add New</span>}
              key="name"
            >
              <Form.Item
                label="Task Name"
                requiredMark
                required
                className="w-full no-margin no-form-item-label-span-24-padding-bottom"
                labelCol={{
                  span: 24,
                }}
              >
                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name="weekDay"
                    rules={[
                      {
                        required: true,
                        message: formatLabel("Field is required."),
                      },
                    ]}
                    labelCol={{
                      span: 24,
                    }}
                  >
                    <Select
                      options={enumToLabelArray(WeekDay).map((item) => ({
                        ...item,
                        value: formatLabel(item.value, "title"),
                      }))}
                      dropdownMatchSelectWidth={false}
                      style={{ minWidth: "28%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    noStyle
                    name="name"
                    labelCol={{
                      span: 24,
                    }}
                  >
                    <Input style={{ width: "72%" }} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </Modal>
    </>
  );
};

export default TaskCreateModal;
