/* eslint-disable max-lines */
import { RegistrationStage } from "@/apis/types";
import { ReactComponent as IconAlert } from "@/assets/images/IconAlert.svg";
import { ReactComponent as IconArrowRightAlt } from "@/assets/images/IconArrowRightAlt.svg";
import { ReactComponent as IconCheck } from "@/assets/images/IconCheckCircleFill.svg";
import { ReactComponent as OverViewIcon } from "@/assets/images/IconOverview.svg";
import { ReactComponent as IconWarning } from "@/assets/images/IconWarning.svg";
import AuthorizedByPermission, {
  EnumAuthHandleType,
} from "@/components/authorize/AuthorizedByPermission";
import { EnumPermissions } from "@/components/authorize/Permissions";
import { calcCapacitySeatsLeft } from "@/components/class/NewClassCapacity/classUtils";
import AutoHeightContainer from "@/components/common/Container/AutoHeightContainer";
import ModalButtonGroup from "@/components/common/ModalButtonGroup";
import { InvoiceStatusMap } from "@/components/finance/Modules/InvoiceDetail/InvoiceDetailCard";
import { useInvoiceDetailModal } from "@/components/finance/Modules/InvoiceDetail/InvoiceDetailModal";
import FeatureWrapper from "@/components/settings/Features/FeatureWrapper";
import {
  KEYWORD_ENUM,
  useFeatureHook,
} from "@/components/settings/Features/utils";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import CourseLink from "@/components/widget/CommonLink/CourseLink";
import { useCheckTaxRateForCurrentYear } from "@/hooks/useCheckTaxRateForCurrentYear";
import { RefreshListRegistrationsContext } from "@/pages/admin/ImportRegistrationNotification/Context";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { PlusCircleFilled } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Space,
  Store,
  Tabs,
  Tooltip,
  Typography,
  message,
} from "@thepiquelab/archus-components-web";
import { formatDate, formatDateTime } from "@thepiquelab/archus-library";
import { Form, Modal } from "antd";
import { isEqual } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ClassType,
  EnrollmentGroupType,
  GetFindClassByIdOnRegistrationModal,
  GetFindClassByIdOnRegistrationModalQuery,
  GetFindClassByIdOnRegistrationModalQueryVariables,
  GetListRegistrationData,
  GetListRegistrationDataQuery,
  GetListRegistrationDataQueryVariables,
  GetRegistrationCache,
  GetRegistrationCacheQuery,
  GetRegistrationCacheQueryVariables,
  GetRegistrationForAction,
  GetRegistrationForActionQuery,
  GetRegistrationForActionQueryVariables,
  LearningArrangementType,
  RegistrationCsv,
  RegistrationCsvQuery,
  RegistrationCsvQueryVariables,
  RegistrationType,
  RegistrationsStatus,
  SelectedLessonInput,
} from "../../../graphql";
import { useLoadMore } from "../../../hooks/useLoadMore";
import { download } from "../../../utils/download";
import { enumToArray, enumToRecord } from "../../../utils/enumUnit";
import formatLabel from "../../../utils/formatLabel";
import EnhancedTable, {
  DataPageColumnProps,
  EnumEnhancedTableWidthType,
  QueryInputs,
} from "../../common/EnhancedTable/EnhancedTable";
import FollowUpStatusSelect, {
  FollowUpType,
} from "../../dashboard/Modules/FollowUpStatusSelect";
import SearchStudentList from "../../widget/SearchStudentList/SearchStudentList";
import { Student, StudentCell } from "../../widget/UserCell/StudentCell";
import { useCheckShouldOfferSeatToWaitingListWarning } from "../CheckWaitingList/useCheckWaitingList";
import ImportRegistrationModal from "../ImportRegistration/ImportRegistrationModal";
import { useImportRegistrationHistoryCheckExport } from "../ImportRegistration/hooks";
import SetAcademicLevelModal from "../RegisterModal/SetAcademicLevelModal/SetAcademicLevelModal";
import {
  removeProcessSessionInfo,
  setProcessSessionInfo,
} from "../RegistrationDetail/common/SMS/utils";
import HybridGroups from "../RegistrationFlow/HybridGroups";
import {
  useApproveRegistration,
  useCreateBillingForRegistration,
} from "../RegistrationHooks";
import { useHaveInCompleteRegistration } from "../RegistrationHooks/useHaveInCompleteRegistration";
import IncompleteRegistrationBar from "../RegistrationTimer/IncompleteRegistrationBar";
import SetDateModal from "../SetDateModal/SetDateModal";
import { RegistrationStatusActions } from "../StatusActions/RegistrationActions";
import { useCheckIfHasTrialInCourse } from "../StatusActions/hooks";
import RegistrationFilter from "./RegistrationFilter";

export const registrationStatusMap = new Map(
  enumToArray(RegistrationsStatus).map((status) => {
    switch (status.value) {
      case RegistrationsStatus.Complete:
        return [status.value, { text: "Complete", color: "#5B9DFF" }];
      case RegistrationsStatus.Paid:
        return [status.value, { text: "Paid", color: "#64DD17" }];
      case RegistrationsStatus.Waitlisted:
        return [status.value, { text: "Waitlisted", color: "#5B9DFF" }];
      case RegistrationsStatus.Reserved:
        return [status.value, { text: "Reserved", color: "#5B9DFF" }];
      case RegistrationsStatus.Expired:
        return [status.value, { text: "Expired", color: "#F36B7F" }];
      default:
        return [status.value, { text: status.key, color: "#4F537B" }];
    }
  })
);

const messageKey = "registration";

const TabNames = [
  { title: "All", key: "default", name: "Registration" },
  {
    title: "Regular",
    key: RegistrationType.Regular,
    name: "Regular Registration",
  },
  { title: "Trial", key: RegistrationType.Trial, name: "Trial Registration" },
  {
    title: "Waiting List",
    key: RegistrationsStatus.Waitlisted,
    name: "Waiting List Registration",
  },
  {
    title: "Reserved",
    key: RegistrationsStatus.Reserved,
    name: "Reserved Registration",
  },
];

type DataItemType =
  GetListRegistrationDataQuery["registrationsWithIndex"]["items"][0];
export type ListRegistrationActiveKeyType =
  | RegistrationType
  | RegistrationsStatus
  | "default";

const ListRegistration: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const isShowLearningArrangement = useFeatureHook([
    KEYWORD_ENUM.global_learning_arrangement,
  ]);
  const isShowHybridGroup = useFeatureHook([
    KEYWORD_ENUM.global_hybrid_grouping,
  ]);

  const [selectedLessons, setSelectedLessons] =
    useState<SelectedLessonInput[]>();

  const [queryRegistrations, { data, loading }, { loadMore, loadMoreLoading }] =
    useLoadMore<
      GetListRegistrationDataQuery,
      GetListRegistrationDataQueryVariables
    >(GetListRegistrationData, "registrationsWithIndex", false, false, false);

  const [queryClass, { data: eligibilityData }] = useLazyQuery<
    GetFindClassByIdOnRegistrationModalQuery,
    GetFindClassByIdOnRegistrationModalQueryVariables
  >(GetFindClassByIdOnRegistrationModal, {
    fetchPolicy: "network-only",
  });

  const [actionId, setActionId] = useState<string>();
  const [actionRegistration, setActionRegistration] =
    useState<GetRegistrationForActionQuery["registration"]>();

  const { refetch: queryRegistration } = useQuery<
    GetRegistrationForActionQuery,
    GetRegistrationForActionQueryVariables
  >(GetRegistrationForAction, {
    skip: true,
  });

  const [queryCsvExport, { data: csvData, loading: exportLoading }] =
    useLazyQuery<RegistrationCsvQuery, RegistrationCsvQueryVariables>(
      RegistrationCsv,
      {
        fetchPolicy: "network-only",
        onCompleted() {
          download(csvData?.csv?.url, messageKey);
        },
        onError(event) {
          message.error({
            content: formatErrorMessage(event),
            key: messageKey,
          });
        },
      }
    );
  const { refetch } = useQuery<
    GetRegistrationCacheQuery,
    GetRegistrationCacheQueryVariables
  >(GetRegistrationCache, { skip: true });

  const { isHaveIncomplete } = useHaveInCompleteRegistration();

  const { data: importRegistrationHistoryData } =
    useImportRegistrationHistoryCheckExport();

  const locationSearch = history?.location?.search;
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [query, setQuery] = useState<GetListRegistrationDataQueryVariables>({});
  const [createRegistrationBilling] = useCreateBillingForRegistration();
  const [selectStudent, setSelectStudent] = useState<Student>();
  const [approveRegistration, { loading: approveLoading }] =
    useApproveRegistration();

  const { checkHasTrial } = useCheckIfHasTrialInCourse();

  // const [visible, setVisible] = useState(false);

  const {
    checkOfferSeatToWaitingListWarning,
    modal: checkShouldOfferSeatToWaitingListWarningModal,
  } = useCheckShouldOfferSeatToWaitingListWarning();

  const [hybridVisible, setHybridVisible] = useState(false);
  const [attendingVisible, setAttendingVisible] = useState(false);
  const [registrationDate, setRegistrationDate] = useState<Date>();
  const [textArea, setTextArea] = useState<string>();
  const [hybridGroupsValue, setHybridGroupsValue] = useState<Store>();
  const [showImportRegistrationModal, setShowImportRegistrationModal] =
    useState<boolean>(false);
  const [dueDate, setDueDate] = useState<Date>(new Date());

  const { handleCheckTaxRate } = useCheckTaxRateForCurrentYear();

  const { isRefresh, setIsRefresh } = useContext(
    RefreshListRegistrationsContext
  );

  const { invoiceDetailModal, setInvoiceDetailModalProps, show } =
    useInvoiceDetailModal();

  const [record, setRecord] = useState<
    DataItemType & {
      isChangeToReservation?: boolean;
    }
  >();

  useEffect(() => {
    if (locationSearch?.includes("click")) {
      setShowRegisterModal(true);
    }
  }, [locationSearch]);

  const [activeKey, setActiveKey] =
    useState<ListRegistrationActiveKeyType>("default");

  const handleExportCsv = (): void => {
    queryCsvExport({
      variables: query,
    });
  };
  const handleRefetch = (id: string): void => {
    setTimeout(() => refetch({ id }), 200);
  };

  const handleOnQueryChange = useCallback(
    (queryInputsTemp?: QueryInputs): void => {
      const { filterInput = {}, ...rest } = queryInputsTemp;

      const { types, status } = formatBaseQuery(activeKey);
      if (!isEqual({ ...rest, filterInput }, query)) {
        setQuery({ ...rest, filterInput });
      }
      queryRegistrations({
        variables: {
          ...rest,
          filterInput: {
            ...filterInput,
            types,
            status,
          },
        },
      });
    },
    [activeKey, query, queryRegistrations]
  );

  const [fields] = useState<DataPageColumnProps<DataItemType>[]>([
    {
      title: formatLabel("Enrollment No."),
      field: "name",
      type: "middle",
      cell: ({ dataItem }) => (
        <Space direction="vertical">
          <Typography.Heading level={4}>{dataItem.name}</Typography.Heading>
          <Typography.Text size="middle">
            {enumToRecord(RegistrationType)[dataItem.type]}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: formatLabel("Student Name"),
      field: "studentName",
      type: "large",
      cell: ({ dataItem }) => {
        const { student } = dataItem;
        return <StudentCell profile={student} />;
      },
    },
    {
      title: formatLabel("Student ID"),
      type: "middle",
      field: "studentId",
      cell: ({ dataItem }) => {
        const { student } = dataItem;
        return (
          <Typography.Heading level={4}>{student?.userId}</Typography.Heading>
        );
      },
    },
    {
      title: formatLabel("Course & Class"),
      type: "large",
      field: "className",
      cell: ({ dataItem }) => {
        const { course, class: classItem } = dataItem;

        return (
          <div className="flex flex-col items-start justify-between gap-2">
            <Typography.Heading level={4}>
              <CourseLink courseId={course.id}>{course.name}</CourseLink>
            </Typography.Heading>
            <Typography.Text size="middle">
              <ClassLink classId={classItem.id}>{classItem.name}</ClassLink>
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: formatLabel("Enrollment Date"),
      field: "enrollmentDate",
      type: "middle",
      cell: ({ dataItem }) => (
        <Typography.Heading level={4}>
          {dataItem?.enrollment?.startDate &&
            formatDate(dataItem?.enrollment?.startDate)}
        </Typography.Heading>
      ),
    },
    {
      title: formatLabel("Last Modified by"),
      field: "lastModifiedBy.fullName",
      type: "middleLarge",
      cell: ({ dataItem }) => (
        <Typography.Heading level={4}>
          <div>{dataItem?.lastModifiedBy?.fullName ?? ""}</div>
          <div>
            {formatDateTime(dataItem.lastModifiedDateTime, {
              format: "SHORT",
            })}
          </div>
        </Typography.Heading>
      ),
    },
    {
      title: "WA Confirmation",
      type: "middle",
      field: "waConfirmation",
      cell: ({ dataItem }) => (
        <Typography.Heading level={4}>
          {formatLabel(dataItem?.waStatus)}
        </Typography.Heading>
      ),
    },
    {
      title: formatLabel("email confirmation"),
      type: EnumEnhancedTableWidthType.M_MEDIUM,
      field: "emailConfirmation",
      cell: ({ dataItem }) => (
        <Typography.Heading level={4}>
          {formatLabel(dataItem?.emailStatus)}
        </Typography.Heading>
      ),
    },
    {
      title: "Reservation F/U Status",
      type: EnumEnhancedTableWidthType.M_MEDIUM,
      field: "enrollment.followUpStatus",
      cell: ({ dataItem }) => (
        <Typography.Heading level={4}>
          <FollowUpStatusSelect
            emptyLabel="-"
            enrollment={{
              id: dataItem?.enrollment?._id,
              followUpStatus: dataItem?.enrollment?.followUpStatus,
              followUpStatusLastModifiedBy: {
                fullName:
                  dataItem?.enrollment?.followUpStatusLastModifiedByFullName,
              },
              followUpStatusLastModifiedDateTime:
                dataItem?.enrollment?.followUpStatusLastModifiedDateTime,
            }}
            registrationStatus={dataItem?.status}
            className="w-full"
            callback={async () => {
              await handleRefetch?.(dataItem?.id);
            }}
            followUpType={FollowUpType.Reservation}
          />
        </Typography.Heading>
      ),
    },
    {
      title: formatLabel("Status"),
      field: "status",
      type: "middle",
      cell: ({ dataItem }) => {
        const status = registrationStatusMap.get(dataItem.status ?? "");
        const seatAvailable =
          calcCapacitySeatsLeft(dataItem?.classCapacity) > 0;
        return (
          <div className="inline-flex items-center">
            <Typography.Heading
              className="mr-2"
              level={4}
              style={{ color: status?.color }}
            >
              {status?.text}
            </Typography.Heading>
            {dataItem?.status === RegistrationsStatus.Waitlisted && (
              <Tooltip
                title={formatLabel(
                  seatAvailable ? "Seat Available" : "Seat Unavailable"
                )}
              >
                {seatAvailable ? (
                  <IconCheck className="text-semantics-green" />
                ) : (
                  <IconAlert />
                )}
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: formatLabel("Payment Status"),
      field: "invoice.status",
      type: "middle",
      cell: ({ dataItem }) => {
        const status = dataItem?.invoice?.status
          ? InvoiceStatusMap[dataItem?.invoice?.status]
          : null;

        if (!status || dataItem.status === RegistrationsStatus.Cancelled) {
          return null;
        }

        return (
          <Typography.Heading level={4} style={{ color: status?.color }}>
            {status?.text}
          </Typography.Heading>
        );
      },
    },
    {
      title: formatLabel("Seats Left"),
      field: "class.capacity.seatsLeft",
      type: "middle",
      cell: ({ dataItem }) => {
        const seatsLeft = calcCapacitySeatsLeft(dataItem?.classCapacity);
        let tooltip: React.ReactNode = seatsLeft?.toString();

        if (seatsLeft <= 2 && seatsLeft > 0) {
          tooltip = (
            <Tooltip
              placement="bottom"
              title={formatLabel("almost full")}
              className="inline-flex items-center"
            >
              {seatsLeft}
              <IconWarning className="ml-2 w-6" />
            </Tooltip>
          );
        } else if (seatsLeft < 0) {
          tooltip = (
            <Tooltip
              placement="bottom"
              title={formatLabel("over capacity")}
              className="inline-flex items-center"
            >
              {seatsLeft}
              <IconAlert className="ml-2 w-6" />
            </Tooltip>
          );
        }

        return (
          <Typography.Heading
            level={4}
            className={seatsLeft === 0 ? "text-primary-red" : null}
          >
            {tooltip}
          </Typography.Heading>
        );
      },
    },
    {
      title: formatLabel("Enrolled"),
      field: "class.capacity.enrolled",
      type: "middle",
      cell: ({ dataItem }) => (
        <Typography.Heading level={4}>
          {dataItem?.classCapacity?.used || 0}
        </Typography.Heading>
      ),
    },
  ]);

  const handleRowClick = (rowData: DataItemType): void => {
    history.push(`/class-arrangement/registration/${rowData.id}`);
  };

  // get type or status value by activeKey, only one of the two has a value.
  const formatBaseQuery = (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    activeKey: ListRegistrationActiveKeyType
  ): { types?: RegistrationType[]; status?: RegistrationsStatus[] } => {
    let types: RegistrationType[];
    let status: RegistrationsStatus[];

    if (
      [RegistrationType.Trial, RegistrationType.Regular].includes(
        activeKey as RegistrationType
      )
    ) {
      types = [activeKey as RegistrationType];
      // status = [RegistrationsStatus.Complete];
    } else if (
      [RegistrationsStatus.Reserved, RegistrationsStatus.Waitlisted].includes(
        activeKey as RegistrationsStatus
      )
    ) {
      status = [activeKey as RegistrationsStatus];
    }

    return {
      types,
      status,
    };
  };

  const handleTypeChange = (val: ListRegistrationActiveKeyType): void => {
    setActiveKey(val);
  };

  const approve = async (input: {
    id: string;
    status?: RegistrationsStatus;
    isChangeToWaitingList?: boolean;
    preferredLearningArrangement?: LearningArrangementType;
    group?: EnrollmentGroupType;
    startDate?: Date;
    dueDate?: Date;
    remarks?: string;
    selectedLessons?: SelectedLessonInput[];
  }): Promise<void> => {
    const { id, status, isChangeToWaitingList, startDate, ...res } = input;
    try {
      if (!isChangeToWaitingList) {
        await approveRegistration({
          variables: {
            input: {
              id,
              startDate,
              isChangeToWaitingList,
              ...res,
            },
          },
        });
      }

      switch (status) {
        case RegistrationsStatus.Waitlisted:
          history.push(`/class-arrangement/registration/create/step2/${id}`);
          break;
        case RegistrationsStatus.Reserved:
          if (!isChangeToWaitingList) {
            await createRegistrationBilling({
              variables: {
                id,
                currentStep: 2,
              },
            });
          }
          history.push(
            `/class-arrangement/registration/create/step${
              isChangeToWaitingList ? "4" : "2"
            }/${id}`,
            { isChangeToWaitingList, ...res }
          );
          break;
        default:
          break;
      }
    } catch (event) {
      message.error(formatErrorMessage(event));
    }
  };

  const onChangeToRegistration = (res: DataItemType): void => {
    if (
      res?.class?.learningArrangement === LearningArrangementType.Hybrid &&
      isShowLearningArrangement
    ) {
      setHybridVisible(true);
      form.setFieldsValue({
        preferredLearningArrangement:
          res?.enrollment?.preferredLearningArrangement,
        group: isShowHybridGroup ? res?.enrollment?.group : null,
      });
      return;
    }
    approve({
      id: res?.id,
      status: res?.status,
      preferredLearningArrangement:
        res?.enrollment?.preferredLearningArrangement,
      group: res?.enrollment?.group,
    });
  };

  const onApprove = async (registration: DataItemType): Promise<void> => {
    try {
      setActionId(registration.id);
      const res = await queryRegistration({ id: registration.id });
      setActionRegistration(res.data?.registration);
      setActionId(null);
      if (registration?.class?.id) {
        queryClass({
          variables: {
            classId: registration?.class?.id,
            studentId: registration?.student?.id,
            skipHoliday: true,
          },
        });
      }
      setRecord({ ...registration, isChangeToReservation: true });

      try {
        await checkOfferSeatToWaitingListWarning(
          registration?.class?.id,
          registration?.startDate,
          {
            isWaitingListToReserved: true,
            studentId: registration?.student?.id,
          }
        );
      } catch (e) {
        return;
      }

      if (
        record?.class?.learningArrangement === LearningArrangementType.Hybrid
      ) {
        setHybridVisible(true);
        form.setFieldsValue({
          preferredLearningArrangement:
            record?.enrollment?.preferredLearningArrangement,
          group: isShowHybridGroup ? record?.enrollment?.group : null,
        });
        return;
      }
      setAttendingVisible(true);
      if (
        registration?.class?.learningArrangement !==
        LearningArrangementType.Hybrid
      ) {
        setAttendingVisible(true);
      } else {
        setHybridVisible(true);
        form.setFieldsValue({
          preferredLearningArrangement:
            registration?.enrollment?.preferredLearningArrangement,
          group: isShowHybridGroup ? registration?.enrollment?.group : null,
        });
      }
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  useEffect(() => {
    if (activeKey) {
      handleOnQueryChange(query);
    }
  }, [activeKey, handleOnQueryChange, query]);

  useEffect(() => {
    if (isRefresh) {
      queryRegistrations();
      setIsRefresh(false);
    }
  }, [isRefresh]);

  const handleSetInvoiceModel = (id: string, registrationId: string): void => {
    setInvoiceDetailModalProps({
      invoiceId: id,
      showBillTitle: true,
      callback: () => async () => {
        await handleRefetch(registrationId);
      },
    });
    show();
  };

  return (
    <div data-testid="ListRegistration" className="h-inherit">
      <AutoHeightContainer>
        <AutoHeightContainer.Header>
          <IncompleteRegistrationBar />
        </AutoHeightContainer.Header>
        <AutoHeightContainer.Content>
          <div className="h-full">
            <EnhancedTable<DataItemType>
              tableName="registrations"
              title={formatLabel("Registrations")}
              fields={fields}
              total={data?.registrationsWithIndex?.total}
              pageSize={data?.registrationsWithIndex?.pageSize}
              pageIndex={data?.registrationsWithIndex?.pageIndex}
              onExportCsv={handleExportCsv}
              showExportPermissions={[EnumPermissions.REGISTRATION_EXPORT]}
              isShowExport
              filter={RegistrationFilter}
              data={data?.registrationsWithIndex?.items.filter(
                (i) => i.status !== RegistrationsStatus.Draft
              )}
              commands={{
                render: (res: DataItemType) => (
                  <RegistrationStatusActions
                    key={res?.id}
                    registration={res}
                    onCollectFee={(value) => {
                      handleSetInvoiceModel(value, res.id);
                    }}
                    onApprove={onApprove}
                    onChangeToRegistration={() => {
                      setRecord(res);
                      onChangeToRegistration(res);
                    }}
                    onAddTrial={async (data) => {
                      try {
                        await checkHasTrial(
                          data?.student?.id,
                          data?.course?.id
                        );

                        history.push(
                          `/class-arrangement/registration/create/step1/${data?.student.id}?preFillClass=${data?.class?.id}`,
                          {
                            class: data?.class,
                            isAddTrial: true,
                          }
                        );
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                    onChangeToWaitingList={() => {
                      approve({
                        id: res?.id,
                        status: res?.status,
                        preferredLearningArrangement:
                          res?.enrollment?.preferredLearningArrangement,
                        group: res?.enrollment?.group,
                        isChangeToWaitingList: true,
                      });
                    }}
                    onCancelRegistration={handleRefetch}
                    loading={actionId === res.id}
                  />
                ),
              }}
              showMenuBar
              searchable
              customToolbarItemMiddle={
                <AuthorizedByPermission
                  permissions={[EnumPermissions.ENROLLMENT_VIEW]}
                  authHandleType={EnumAuthHandleType.HIDE}
                >
                  <FeatureWrapper keywords={KEYWORD_ENUM.global_gap}>
                    <Button
                      className="inline-flex items-center"
                      onClick={() => {
                        history.push(
                          "/class-arrangement/registration/overview?regular=true"
                        );
                      }}
                    >
                      <OverViewIcon className="text-primary-navyLight mr-2" />
                      <span>{formatLabel("GAP Enrollment overview")}</span>
                    </Button>
                  </FeatureWrapper>
                </AuthorizedByPermission>
              }
              addButtonProp={{
                disable: isHaveIncomplete,
                label: formatLabel("Register a Student"),
                permissions: [EnumPermissions.REGISTRATION_UPDATE],
                onClick(): void {
                  if (!handleCheckTaxRate()) {
                    return;
                  }
                  setShowRegisterModal(true);
                },
                items: [
                  {
                    content: "Import Registration",
                    key: "1",
                    disable:
                      !!importRegistrationHistoryData?.bulkCreateHistory?.id,
                  },
                ],
                onItemClick: ({ key }) => {
                  switch (key) {
                    case "1":
                      setShowImportRegistrationModal(true);
                      break;

                    default:
                      break;
                  }
                },
              }}
              toolbar={
                <Tabs
                  activeKey={activeKey}
                  onChange={(val: ListRegistrationActiveKeyType) => {
                    handleTypeChange(val);
                  }}
                  className="leading-tight font_semibold text_sm text__primary"
                >
                  {TabNames?.map((i) => (
                    <Tabs.TabPane tab={formatLabel(i.title)} key={i.key} />
                  ))}
                </Tabs>
              }
              loading={loading}
              loadMoreLoading={loadMoreLoading}
              loadMore={loadMore}
              onQueryChange={handleOnQueryChange}
              onRowClick={(rowData) => handleRowClick(rowData)}
            />
          </div>
        </AutoHeightContainer.Content>
      </AutoHeightContainer>

      <Modal
        title={formatLabel("Register a Student")}
        visible={showRegisterModal}
        onCancel={() => {
          setShowRegisterModal(false);
          removeProcessSessionInfo();
        }}
        destroyOnClose
        footer={
          <div className="flex justify-between items-center">
            <Col>
              <h3 className="font_bold text__primary">Can't find a student?</h3>
            </Col>
            <Col>
              <AuthorizedByPermission
                permissions={[EnumPermissions.STUDENT_UPDATE]}
                authHandleType={EnumAuthHandleType.HIDE}
              >
                <Button
                  type="primary"
                  className="inline-flex items-center"
                  onClick={() => {
                    setShowRegisterModal(false);
                    setProcessSessionInfo([
                      {
                        process: "student",
                        source: "add_student",
                        sourceDetails: {
                          isDelete: "false",
                          url: "/class-arrangement/registration",
                        },
                      },
                    ]);
                    history.push(`/customers/students/create`);
                  }}
                >
                  <span>{formatLabel("Add New Student")}</span>
                  <IconArrowRightAlt
                    className={"ml-2"}
                    style={{ marginRight: 0 }}
                  />
                </Button>
              </AuthorizedByPermission>
            </Col>
          </div>
        }
      >
        <SearchStudentList
          action={(s) => (
            <Button
              type="primary"
              className="inline-flex items-center"
              onClick={() => {
                if (!s.academicLevel) {
                  setSelectStudent(s);
                  return;
                }
                if (!s.primaryParent) {
                  message.error("This student has not linked with any parent!");
                  return;
                }
                history.push(
                  `/class-arrangement/registration/create/step1/${s.id}`
                );
              }}
            >
              <span>{formatLabel("Register")}</span>
              <IconArrowRightAlt className="ml-2" />
            </Button>
          )}
        />
      </Modal>
      <Modal
        title={
          <Typography.Heading level={3}>
            {formatLabel("Select Date")}
          </Typography.Heading>
        }
        visible={attendingVisible}
        destroyOnClose
        onCancel={() => {
          setAttendingVisible(false);
          setRegistrationDate(null);
          setTextArea(null);
        }}
        footer={
          <ModalButtonGroup
            okText="Confirm"
            okButtonProps={{
              icon: <PlusCircleFilled />,
            }}
            onOK={async () => {
              if (
                eligibilityData?.classFromStudent?.type ===
                  ClassType.Workshop &&
                !selectedLessons?.length
              ) {
                message.warn("Please select at least one attending date.");
                return;
              }
              await approve({
                startDate: registrationDate || undefined,
                id: record?.id,
                selectedLessons: selectedLessons?.length
                  ? selectedLessons
                  : undefined,
                status: record?.status,
                dueDate: dueDate || undefined,
                preferredLearningArrangement:
                  hybridGroupsValue?.preferredLearningArrangement ||
                  record?.enrollment?.preferredLearningArrangement,
                group: hybridGroupsValue?.group || record?.enrollment?.group,
                remarks: textArea || undefined,
              });
            }}
            onCancel={() => {
              setAttendingVisible(false);
              setRegistrationDate(null);
              setTextArea(null);
            }}
            confirmLoading={approveLoading}
          />
        }
      >
        <SetDateModal
          stage={RegistrationStage.Reservation}
          height={"350px"}
          classType={eligibilityData?.classFromStudent?.type}
          registrationStartDate={record?.startDate}
          selectClassId={record?.class?.id}
          onRegistrationDateChange={setRegistrationDate}
          onTextAreaChange={setTextArea}
          // eligibility={eligibilityData?.classFromStudent?.eligibility?.find(
          //   (e) => e.stage === RegistrationStage.Reservation
          // )}
          onAttendingLessonsChange={(v) => {
            setSelectedLessons(v);
          }}
          onDueDateChange={setDueDate}
        />
      </Modal>

      {checkShouldOfferSeatToWaitingListWarningModal}

      <Modal
        visible={hybridVisible}
        destroyOnClose
        footer={
          <ModalButtonGroup
            onOK={() => {
              form.submit();
            }}
            okText={formatLabel("Next")}
            onCancel={() => {
              setHybridVisible(false);
              setRecord(null);
              form.resetFields();
            }}
            confirmLoading={approveLoading}
          />
        }
        onCancel={() => {
          setHybridVisible(false);
          form.resetFields();
          setRecord(null);
        }}
        title={formatLabel("Select Preferred Learning Arrangement")}
        className="min-w-md"
      >
        <HybridGroups
          classId={record?.class?.id}
          form={form}
          onSubmit={(value) => {
            if (record?.isChangeToReservation) {
              setHybridVisible(false);
              setAttendingVisible(true);
              setHybridGroupsValue(value);
            } else {
              approve({
                id: record?.id,
                status: record?.status,
                ...value,
              });
            }
          }}
        />
      </Modal>

      <SetAcademicLevelModal
        visible={!!selectStudent?.id}
        profile={selectStudent}
        onCancel={() => {
          setSelectStudent(null);
        }}
      />

      {invoiceDetailModal}

      <ImportRegistrationModal
        visible={showImportRegistrationModal}
        onClose={() => {
          setShowImportRegistrationModal(false);
        }}
      />
    </div>
  );
};

export default ListRegistration;
