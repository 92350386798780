import { TeacherOptions } from "@/components/widget/FilterFormOption/TeacherOptions";
import { useLevelOptions } from "@/components/widget/FilterFormOption/hooks/useLevelOptions";
import { useQuery } from "@apollo/client";
import {
  Button,
  FilterDrawer,
  FilterForm,
  FilterFormItem,
  FilterItemOptionTypeEnum,
  FormInstance,
  Row,
  Spin,
  TimePeriodEnum,
} from "@thepiquelab/archus-components-web";
import moment from "moment";
import React, { useEffect, useMemo, useRef } from "react";
import {
  ClassType,
  GetRegistrationFilterOptions,
  GetRegistrationFilterOptionsQuery,
  GetRegistrationFilterOptionsQueryVariables,
  LessonFilterInput,
} from "../../../graphql";
import { enumToArray, enumToLabelArray } from "../../../utils/enumUnit";
import formatLabel from "../../../utils/formatLabel";
import { FilterProps } from "../../common/AdvanceGrid/types";

const RegistrationFilter: React.FC<FilterProps<LessonFilterInput>> = (
  props
) => {
  const { value, visible, onChange, onClose } = props;
  const formRef = useRef<FormInstance<any>>();

  const { data, loading } = useQuery<
    GetRegistrationFilterOptionsQuery,
    GetRegistrationFilterOptionsQueryVariables
  >(GetRegistrationFilterOptions, {
    variables: {
      year: Number(moment().format("YYYY")),
    },
    fetchPolicy: "network-only",
  });
  const { options: levelOptions, loading: levelLoading } = useLevelOptions();

  const week = useMemo(() => {
    if (!data?.academicYear) return null;
    const { academicWeeks: weeksOfYear } = data?.academicYear;

    return weeksOfYear.find((w) =>
      moment().isBetween(w.startDate, w.endDate, "day", "[]")
    );
  }, [data?.academicYear]);

  const term = useMemo(() => {
    if (!data?.academicYear?.terms) return null;
    const { term1Period, term2Period, term3Period, term4Period } =
      data?.academicYear?.terms?.[0];
    const termArray = [
      { ...term1Period },
      { ...term2Period },
      { ...term3Period },
      { ...term4Period },
    ];

    return termArray
      ?.map((i) => ({
        ...i,
        start: moment(i?.start).startOf("day").format(),
        end: moment(i?.end).endOf("day").format(),
      }))
      ?.find((i) => moment().isBetween(i?.start, i?.end));
  }, [data?.academicYear?.terms]);

  const onFinish = (val: any): void => {
    const { venueIds, courseIds, ...res } = val;
    const param: any = { ...res };

    if (
      (val?.dateRange as any)?.selectedValue ===
      TimePeriodEnum.CURRENTACADEMICWEEK
    ) {
      param.dateRange = {
        start: moment(week.startDate).startOf("day").format(),
        end: moment(week.endDate).endOf("day").format(),
        selectedValue: TimePeriodEnum.CURRENTACADEMICWEEK,
      } as any;
    }

    if ((val?.dateRange as any)?.selectedValue === TimePeriodEnum.THISTERM) {
      param.dateRange = {
        start: term.start,
        end: term.end,
        selectedValue: TimePeriodEnum.THISTERM,
      } as any;
    }

    onChange(param, val);
  };

  useEffect(() => {
    if (visible && value && formRef?.current) {
      formRef?.current?.setFieldsValue(value);
    }
  }, [visible]);

  return (
    <FilterDrawer
      visible={visible}
      onClose={() => {
        formRef?.current?.resetFields();
        formRef.current = null;
        onClose?.();
      }}
    >
      <FilterDrawer.Content>
        <Spin spinning={loading || levelLoading || !data}>
          <FilterForm
            ref={formRef}
            onFinish={(val) => {
              formRef.current = null;
              onFinish(val);
            }}
            filterOptions={[
              {
                label: formatLabel("Enrollment Dates"),
                field: "dateRange",
                type: FilterItemOptionTypeEnum.ReactNode,
                render: () => (
                  <FilterFormItem.ItemTimePeriod
                    data={enumToLabelArray(TimePeriodEnum)}
                  />
                ),
              },
              {
                label: "Teacher",
                field: "teacherIds",
                type: FilterItemOptionTypeEnum.ReactNode,
                render: () => (
                  <TeacherOptions label={formatLabel("All Teachers")} />
                ),
              },
              {
                label: "Level",
                field: "academicLevelIds",
                type: FilterItemOptionTypeEnum.Checkbox,
                options: {
                  label: formatLabel("All Levels"),
                  options: levelOptions,
                },
              },
              {
                label: "Class Type",
                field: "classTypes",
                type: FilterItemOptionTypeEnum.Checkbox,
                options: {
                  label: formatLabel("All Class Types"),
                  options: enumToArray(ClassType)?.map((d) => ({
                    label: d.key,
                    value: d.value,
                  })),
                },
              },
            ]}
          />
        </Spin>
      </FilterDrawer.Content>
      <FilterDrawer.Footer>
        <Row justify="end">
          <Button
            onClick={() => {
              formRef?.current?.resetFields();
            }}
          >
            Clear
          </Button>
          <Button
            className="c-ml-3"
            type="primary"
            onClick={() => {
              formRef?.current?.submit();
            }}
          >
            Apply
          </Button>
        </Row>
      </FilterDrawer.Footer>
    </FilterDrawer>
  );
};

export default RegistrationFilter;
