import { RegistrationStage } from "@/apis/types";
import ModalButtonGroup from "@/components/common/ModalButtonGroup";
import {
  KEYWORD_ENUM,
  useFeatureHook,
} from "@/components/settings/Features/utils";
import { StudentCell } from "@/components/widget/UserCell/StudentCell";
import {
  ClassType,
  EnrollmentGroupType,
  GetFindClassByIdOnRegistrationModalQuery,
  GetRegistrationForWaitingListActionQuery,
  LearningArrangementType,
  RegistrationsStatus,
  SelectedLessonInput,
} from "@/graphql";
import { setSessionRegistrationStage } from "@/utils/checkSessionStorageHybrid";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { PlusCircleFilled } from "@ant-design/icons";
import { Store, Typography } from "@thepiquelab/archus-components-web";
import { formatDate, formatDateTime } from "@thepiquelab/archus-library";
import { FormInstance, Modal, Table, message } from "antd";
import { FC, memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { setProcessSessionInfo } from "../RegistrationDetail/common/SMS/utils";
import HybridGroups from "../RegistrationFlow/HybridGroups";
import { useApproveRegistration } from "../RegistrationHooks";
import SetDateModal from "../SetDateModal/SetDateModal";

interface Props {
  visible?: boolean;
  hybridVisible?: boolean;
  attendingVisible?: boolean;
  setAttendingVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setHybridVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  actionRegistration?: GetRegistrationForWaitingListActionQuery;
  eligibilityData: GetFindClassByIdOnRegistrationModalQuery;
  form: FormInstance<any>;
}

const SetWaitingListRegistration: FC<Props> = (props) => {
  const {
    eligibilityData,
    actionRegistration,
    visible,
    form,
    hybridVisible,
    attendingVisible,
    setAttendingVisible,
    setHybridVisible,
    setVisible,
  } = props;
  const [approveRegistration, { loading: approveLoading }] =
    useApproveRegistration();

  const isShowHybridGroup = useFeatureHook([
    KEYWORD_ENUM.global_hybrid_grouping,
  ]);

  const history = useHistory();

  const [hybridGroupsValue, setHybridGroupsValue] = useState<Store>();
  const [registrationDate, setRegistrationDate] = useState<Date>();
  const [textArea, setTextArea] = useState<string>();
  const [selectedLessons, setSelectedLessons] =
    useState<SelectedLessonInput[]>();
  const [dueDate, setDueDate] = useState<Date>(new Date());

  const approve = async (input: {
    id: string;
    status?: RegistrationsStatus;
    preferredLearningArrangement?: LearningArrangementType;
    group?: EnrollmentGroupType;
    startDate?: Date;
    dueDate?: Date;
    remarks?: string;
    selectedLessons?: SelectedLessonInput[];
  }): Promise<void> => {
    const { id, status, startDate, ...res } = input;
    try {
      await approveRegistration({
        variables: {
          input: {
            id,
            startDate,
            isChangeToWaitingList: false,
            ...res,
          },
        },
      });
      setSessionRegistrationStage(RegistrationStage.Reservation);
      setProcessSessionInfo([
        {
          process: "registration",
          source: "registration_list",
          sourceDetails: {
            isDelete: "false",
            url: "/dashboard/waiting-list-overview",
          },
        },
      ]);
      history.push(`/class-arrangement/registration/create/step2/${id}`);
    } catch (event) {
      message.error(formatErrorMessage(event));
    }
  };

  return (
    <>
      <Modal
        title={formatLabel("Approve Waiting list")}
        visible={visible}
        width={800}
        onCancel={() => {
          setVisible(false);
        }}
        destroyOnClose
        footer={
          <ModalButtonGroup
            onOK={() => {
              setVisible(false);
              if (
                actionRegistration?.registration?.class?.learningArrangement ===
                LearningArrangementType.Hybrid
              ) {
                setHybridVisible(true);
                form.setFieldsValue({
                  preferredLearningArrangement:
                    actionRegistration?.registration?.enrollment
                      ?.preferredLearningArrangement,
                  group: isShowHybridGroup
                    ? actionRegistration?.registration?.enrollment?.group
                    : null,
                });
                return;
              }
              setAttendingVisible(true);
            }}
            onCancel={() => {
              setVisible(false);
            }}
          />
        }
      >
        <Table
          title={() => (
            <Typography.Text>
              Are you sure you want to proceed? There are other students also on
              the waiting list!
            </Typography.Text>
          )}
          columns={[
            {
              title: formatLabel("Student"),
              dataIndex: "student.fullName",
              render: (val, row) => <StudentCell profile={row.student} />,
            },
            {
              title: formatLabel("Waiting start date"),
              dataIndex: "startDate",
              render: (val, row) => <>{formatDate(val, { format: "SHORT" })}</>,
            },
            {
              title: formatLabel("Waiting List Create Time"),
              dataIndex: "createDateTime",
              render: (val, row) => (
                <>{formatDateTime(val, { format: "SHORT" })}</>
              ),
            },
          ]}
          dataSource={actionRegistration?.registration?.effectedWaitingLists}
          pagination={false}
        />
      </Modal>

      <Modal
        visible={hybridVisible}
        destroyOnClose
        footer={
          <ModalButtonGroup
            onOK={() => {
              form.submit();
            }}
            okText={formatLabel("Next")}
            onCancel={() => {
              form.resetFields();
              setHybridVisible(false);
            }}
          />
        }
        onCancel={() => {
          form.resetFields();
          setHybridVisible(false);
        }}
        title={formatLabel("Select Preferred Learning Arrangement")}
        className="min-w-md"
      >
        <HybridGroups
          classId={actionRegistration?.registration?.class?.id}
          form={form}
          onSubmit={(value) => {
            setHybridVisible(false);
            setAttendingVisible(true);
            setHybridGroupsValue(value);
          }}
        />
      </Modal>

      <Modal
        title={
          <Typography.Heading level={3}>
            {formatLabel("Select Date")}
          </Typography.Heading>
        }
        visible={attendingVisible}
        destroyOnClose
        onCancel={() => {
          setRegistrationDate(null);
          setTextArea(null);
          setAttendingVisible(false);
        }}
        footer={
          <ModalButtonGroup
            okText="Confirm"
            okButtonProps={{
              icon: <PlusCircleFilled />,
            }}
            onOK={async () => {
              if (
                eligibilityData?.classFromStudent?.type ===
                  ClassType.Workshop &&
                !selectedLessons?.length
              ) {
                message.warn("Please select at least one attending date.");
                return;
              }
              await approve({
                startDate: registrationDate || undefined,
                id: actionRegistration?.registration?.id,
                selectedLessons: selectedLessons?.length
                  ? selectedLessons
                  : undefined,
                status: actionRegistration?.registration?.status,
                dueDate: dueDate || undefined,
                preferredLearningArrangement:
                  hybridGroupsValue?.preferredLearningArrangement ||
                  actionRegistration?.registration?.enrollment
                    ?.preferredLearningArrangement,
                group:
                  hybridGroupsValue?.group ||
                  actionRegistration?.registration?.enrollment?.group,
                remarks: textArea || undefined,
              });
            }}
            onCancel={() => {
              setRegistrationDate(null);
              setTextArea(null);
              setAttendingVisible(false);
            }}
            confirmLoading={approveLoading}
          />
        }
      >
        <SetDateModal
          stage={RegistrationStage.Reservation}
          height={"350px"}
          classType={eligibilityData?.classFromStudent?.type}
          registrationStartDate={actionRegistration?.registration?.startDate}
          selectClassId={actionRegistration?.registration?.class?.id}
          onRegistrationDateChange={setRegistrationDate}
          onTextAreaChange={setTextArea}
          eligibility={eligibilityData?.classFromStudent?.eligibility?.find(
            (e) => e.stage.toUpperCase() === RegistrationStage.Reservation
          )}
          onAttendingLessonsChange={(v) => {
            setSelectedLessons(v);
          }}
          onDueDateChange={setDueDate}
        />
      </Modal>
    </>
  );
};

export default memo(SetWaitingListRegistration);
