/* eslint-disable max-lines */
import { ReactComponent as IconError } from "@/assets/images/IconError.svg";
import { ReactComponent as IconOverview } from "@/assets/images/IconOverview.svg";
import { ReactComponent as IconPinned } from "@/assets/images/IconPinned.svg";
import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  TaskFieldsFragment,
  TaskIndexFieldsFragment,
  TaskStatus,
  TaskType,
} from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { useHistory } from "react-router-dom";

import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import CommonBadge from "@/components/widget/Badge/CommonBadge";
import {
  Badge,
  Dropdown,
  Menu,
  Tooltip,
  Typography,
  message,
} from "@thepiquelab/archus-components-web";
import { formatDateTime, formatPhoneNumber } from "@thepiquelab/archus-library";
import Table from "antd/es/table";
import { maxBy } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import TaskActions from "./TaskActions";
import TaskDueDatePicker from "./TaskDueDatePicker";
import {
  getIsNoSelection,
  getIsNotAllowedToComplete,
  getIsNotAllowedToSend,
} from "./TaskTableUtil";
import { TaskCountType } from "./hooks";
import { TaskStatusTagMap } from "./utils";

import AuthorizedByPermission, {
  EnumAuthHandleType,
} from "@/components/authorize/AuthorizedByPermission";
import { EnumPermissions } from "@/components/authorize/Permissions";
import { TableInCardSkeleton } from "@/components/common/Skeleton/TableSkeleton";
import ParentLink from "@/components/widget/CommonLink/ParentLink";
import StudentLink from "@/components/widget/CommonLink/StudentLink";
import { CaretDownOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/lib/table/interface";
import {
  QueryTaskIndexProps,
  checkIsFollowUpTask,
  checkIsReminderTask,
} from "../index.utils";
import OperationalTaskDetailModal from "./TaskDetail/OperationalTaskDetailModal";
import TaskDetailModal from "./TaskDetail/TaskDetailModal";
import "./TaskTable.scss";
import { useUpdateTasks } from "./useUpdateTasks";

interface Props {
  columns: AdvanceTableColumnType[];
  data: (TaskIndexFieldsFragment | TaskFieldsFragment)[];
  loading: boolean;
  title: string;
  taskType: TaskType;
  callback: (options?: QueryTaskIndexProps) => void;
  hideHeader?: boolean;
  pagination?: TaskPagination;
  count?: TaskCountType;
  innerFilter?: React.ReactNode;
}

export interface TaskPagination {
  pageIndex: number;
  pageSize: number;
  total: number;
  onChange?: (page: number, pageSize: number) => void;
}

const ParentWASortKey = "parentWhatsappAccount.number.number";

export const formatSendLabel = (type: TaskType): string => {
  if (checkIsFollowUpTask(type)) {
    return "Send Follow Up";
  }
  if (checkIsReminderTask(type)) {
    return "Send Reminder";
  }
  if (type?.startsWith("Reminder")) {
    return "Send Reminder";
  }
  return "Send Follow Up";
};
export enum CustomCommonColumnKey {
  TaskStatus = "TaskStatus",
  StudentName = "StudentName",
  StudentID = "StudentID",
  PrimaryParentName = "PrimaryParentName",
  PrimaryParentMobile = "PrimaryParentMobile",
  PrimaryParentWALine = "PrimaryParentWALine",
}
export const CustomCommonColumn = {
  [CustomCommonColumnKey.StudentName]: {
    title: formatLabel("Student Name"),
    width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
    sorter: true,
    key: "student.fullName",
    render(value: string, record: TaskIndexFieldsFragment) {
      const { enrollment } = record;
      const { student } = record || {};
      return (
        <StudentLink id={student?.id}>{student?.fullName || "-"}</StudentLink>
      );
    },
  },
  [CustomCommonColumnKey.StudentID]: {
    title: "Student ID",
    width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
    render(value: string, record: TaskIndexFieldsFragment) {
      const { student } = record;
      return <>{student?.userId || "-"}</>;
    },
  },
  [CustomCommonColumnKey.PrimaryParentName]: {
    title: "Primary Parent Name",
    width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
    sorter: true,
    key: "parent.fullName",
    render(value: string, record: TaskIndexFieldsFragment) {
      const { parent } = record || {};
      return <ParentLink id={parent?.id}>{parent?.fullName || "-"}</ParentLink>;
    },
  },
  [CustomCommonColumnKey.PrimaryParentMobile]: {
    title: "Primary Parent Mobile",
    width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
    render(value: string, record: TaskIndexFieldsFragment) {
      const { parent } = record || {};
      return <>{`${formatPhoneNumber(parent?.mobile)}`}</>;
    },
  },
  [CustomCommonColumnKey.PrimaryParentWALine]: {
    title: "Primary Parent WA Line",
    sorter: true,
    key: ParentWASortKey,
    width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
    render(value: string, record: TaskIndexFieldsFragment) {
      const { parentWhatsappAccount } = record || {};

      return parentWhatsappAccount?.number?.number ? (
        <span className="font_regular">
          {`[${parentWhatsappAccount?.number?.number?.slice(0, 4)}]`}
        </span>
      ) : (
        <></>
      );
    },
  },
  [CustomCommonColumnKey.TaskStatus]: {
    title: formatLabel("Task Status"),
    sorter: true,
    key: "statusWithSort",
    width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
    render(_value: string, record: TaskIndexFieldsFragment) {
      const { status } = record;

      return (
        <CommonBadge
          textColor={TaskStatusTagMap[status]?.textColor}
          bgColor={TaskStatusTagMap[status]?.bgColor}
          label={status}
        />
      );
    },
  },
};

const TaskTable: React.FC<Props> = (props) => {
  const {
    columns,
    data,
    loading,
    title,
    taskType,
    callback,
    hideHeader,
    pagination,
    count,
    innerFilter,
  } = props;
  const history = useHistory();
  const noSend = getIsNotAllowedToSend(taskType);
  const noComplete = getIsNotAllowedToComplete(taskType);
  const noSelection = getIsNoSelection(taskType);
  const [taskIdToEdit, setTaskIdToEdit] = useState("");
  const [sorter, setSorter] = useState<SorterResult<any>>();
  const [scrollY, setScrollY] = useState<number>();
  const taskTableRef = useRef();
  const queryAllTaskCallback = (): void => {
    callback({
      pagination: {
        pageIndex: pagination?.pageIndex,
        pageSize: pagination?.pageSize,
      },
      sorter: sorter?.order
        ? {
            sortKey: sorter?.columnKey as unknown as string,
            sort: sorter?.order,
          }
        : null,
    });
  };

  const {
    completeTasks,
    voidTasks,
    completeTaskLoading,
    voidTaskLoading,
    modal,
    selectedIds,
    setSelectedIds,
  } = useUpdateTasks(taskType, queryAllTaskCallback);

  const actionColumn = [
    {
      title: formatLabel("Task Created At"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(_value: string, record: TaskIndexFieldsFragment) {
        const { createDateTime } = record || {};

        return <>{formatDateTime(createDateTime, { format: "SHORT" })}</>;
      },
    },
    {
      title: formatLabel("Task Last Modified At"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render(_value: string, record: TaskIndexFieldsFragment) {
        const { lastModifiedDateTime } = record || {};

        return <>{formatDateTime(lastModifiedDateTime, { format: "SHORT" })}</>;
      },
    },
    {
      title: formatLabel("Task Due At"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(_value: string, record: TaskIndexFieldsFragment) {
        return (
          <TaskDueDatePicker task={record} callback={queryAllTaskCallback} />
        );
      },
    },

    {
      title: formatLabel("Remarks"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      hide: taskType === TaskType.Operational,
      render(_value: string, record: TaskIndexFieldsFragment) {
        const { pinnedRemark, flagRemark, remarks } = record;

        const latestRemark = maxBy(remarks || [], (remark) =>
          moment(remark.createDateTime).unix()
        );

        return (
          <div className="inline-flex items-center">
            {latestRemark ? (
              <Tooltip title={latestRemark?.content}>
                <IconOverview className="text-primary-navy mr-4" />
              </Tooltip>
            ) : null}
            {pinnedRemark ? (
              <Tooltip title={pinnedRemark?.content}>
                <IconPinned className="text-primary-navy mr-4" />
              </Tooltip>
            ) : null}
            {flagRemark ? (
              <Tooltip title={flagRemark?.content}>
                <IconError />
              </Tooltip>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Action",
      fixed: "right",
      hide: taskType === TaskType.FollowUpFeeBalance,
      width:
        enumEnhancedTableWidthType[EnumEnhancedTableWidthType.XS_EXTRA_SMALL],
      render: (value: string, record: TaskIndexFieldsFragment) => (
        <div
          className="inline-flex items-center"
          onDoubleClick={(e) => {
            e?.stopPropagation();
            e?.preventDefault();
          }}
        >
          <TaskActions
            taskId={record.id}
            status={record.status}
            taskType={taskType}
            callback={queryAllTaskCallback}
          />
        </div>
      ),
    },
  ].filter((item) => !item?.hide);

  const tableColumns = [
    CustomCommonColumn[CustomCommonColumnKey.TaskStatus],
    ...columns,
    ...actionColumn,
  ];

  const width = useMemo(
    () =>
      tableColumns
        .map((c) => c.width || 100)
        .reduce((w1: number, w2: number) => w1 + w2),
    [tableColumns]
  );

  const handleComplete = (ids: string[]): void => {
    if (!selectedIds?.length) {
      message.warn("Please select at least one task!");
      return;
    }
    completeTasks(ids);
  };

  const handleVoid = (ids: string[]): void => {
    if (!selectedIds?.length) {
      message.warn("Please select at least one task!");
      return;
    }
    voidTasks(ids);
  };

  const getSendFollowUpParams = (): {
    taskIds?: string[];
    registrationIds?: string[];
    attendanceIds?: string[];
    invoiceIds?: string[];
    enrollmentIds?: string[];
    taskType?: TaskType;
  } => {
    const tasks = data?.filter((record) => selectedIds?.includes(record.id));
    if (taskType === TaskType.FollowUpTrial) {
      return {
        taskIds: tasks.map((record) => record?.id),
        taskType,
      };
    }
    if (taskType === TaskType.FollowUpReservation) {
      return {
        registrationIds: tasks.map((record) => record?.registration?.id),
        taskType,
      };
    }
    if (taskType === TaskType.FollowUpAbsent) {
      const ids = tasks.map((record) => record?.attendance?.id) || [];
      return {
        attendanceIds: [...new Set(ids)],
        taskType,
      };
    }
    if (taskType === TaskType.FollowUpReplacementLessonOffer) {
      return {
        taskIds: tasks.map((record) => record?.id),
        taskType,
      };
    }
    if (taskType === TaskType.FollowUpPayment) {
      return {
        invoiceIds: tasks.map((record) => record?.invoice?.id),
        taskType,
      };
    }
    if (taskType === TaskType.ReminderTrialLesson) {
      return {
        enrollmentIds: tasks.map((record) => record?.enrollment?.id),
        taskType,
      };
    }
    if (taskType === TaskType.ReminderAdditionalLesson) {
      const ids = tasks.map((record) => record?.enrollment?.id) || [];
      return {
        enrollmentIds: [...new Set(ids)],
        taskType,
      };
    }
    if (taskType === TaskType.ReminderChangeClass) {
      return {
        taskIds: tasks.map((record) => record?.id),
        taskType,
      };
    }
    if (taskType === TaskType.ReminderLesson) {
      return {
        taskIds: tasks.map((record) => record?.id),
        taskType,
      };
    }
    if (taskType === TaskType.ReminderWorkshopReplacementLesson) {
      const ids = tasks.map((record) => record?.attendance?.id) || [];
      return {
        attendanceIds: [...new Set(ids)],
        taskType,
      };
    }
    if (taskType === TaskType.ReminderAdditionalLessonOffer) {
      const ids = tasks.map((record) => record?.enrollment?.id) || [];
      return {
        enrollmentIds: [...new Set(ids)],
        taskType,
      };
    }
    if (taskType === TaskType.ReminderLessonUnderHoliday) {
      return {
        taskIds: tasks.map((record) => record?.id),
        taskType,
      };
    }
    if (taskType === TaskType.FollowUpTermBilling) {
      const ids = tasks.map((record) => record?.invoice?.id);
      return {
        invoiceIds: [...new Set(ids)],
        taskType,
      };
    }

    if (taskType === TaskType.FollowUpTrialRequest) {
      return {
        taskIds: tasks.map((record) => record?.id),
        taskType,
      };
    }
    return {};
  };

  const handleSendReminder = (): void => {
    if (!selectedIds?.length) {
      message.warn("Please select at least one task!");
      return;
    }

    localStorage.setItem("taskIds", JSON.stringify(selectedIds));
    history.push(`/dashboard/send-followup`, getSendFollowUpParams());
  };

  useEffect(() => {
    const targetElement = taskTableRef.current;
    if (targetElement) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const topDistance = targetElement?.getBoundingClientRect()?.top;

      const containerHeight = window?.innerHeight - topDistance - 236;
      if (taskType === TaskType.Operational) {
        return;
      }
      setScrollY(containerHeight);
    }
  }, []);

  const scroll = {
    x: width,
  };
  if (scrollY) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    scroll.y = scrollY;
  }

  return loading ? (
    <div className={`flex flex-col gap-6`}>
      <TableInCardSkeleton active size={5} />
    </div>
  ) : (
    <>
      <Table
        onHeaderRow={(columns, index) => ({
          className: "border-b",
        })}
        ref={taskTableRef}
        id="taskTable"
        className={`task-table`}
        title={() =>
          !hideHeader ? (
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-1">
                <Typography.Heading level={2} className="font-semibold">
                  {`${title} - ${pagination?.total} Task(s)`}
                </Typography.Heading>
                {count?.[taskType]?.overdue ? (
                  <div className="inline-flex item-center">
                    <Badge
                      color="#F36B7F"
                      text={`Overdue: ${count?.[taskType]?.overdue}`}
                      className="font-semibold"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="inline-flex gap-2">
                {innerFilter}
                <Dropdown.Button
                  icon={<CaretDownOutlined />}
                  overlay={
                    <Menu>
                      <Menu.Item key="void-all" onClick={() => voidTasks()}>
                        {formatLabel("Void All")}
                      </Menu.Item>
                    </Menu>
                  }
                  onClick={() => completeTasks()}
                  loading={completeTaskLoading || voidTaskLoading}
                >
                  {formatLabel("Complete All")}
                </Dropdown.Button>
                <Dropdown.Button
                  type="primary"
                  icon={<CaretDownOutlined />}
                  overlay={
                    <Menu>
                      <AuthorizedByPermission
                        permissions={[EnumPermissions.NOTIFICATION_LOG_UPDATE]}
                        authHandleType={EnumAuthHandleType.HIDE}
                      >
                        <Menu.Item key="reminder" onClick={handleSendReminder}>
                          {formatSendLabel(taskType)}
                        </Menu.Item>
                      </AuthorizedByPermission>
                      <Menu.Item
                        key="void"
                        onClick={() => handleVoid(selectedIds)}
                      >
                        {formatLabel("Void Task")}
                      </Menu.Item>
                    </Menu>
                  }
                  loading={completeTaskLoading || voidTaskLoading}
                  onClick={() => {
                    handleComplete(selectedIds);
                  }}
                >
                  {formatLabel("Mark as Complete")}
                </Dropdown.Button>
              </div>
            </div>
          ) : null
        }
        onChange={(
          pagination,
          filters,
          sorter: SorterResult<any>,
          { action }
        ) => {
          if (action === "sort") {
            setSorter(sorter);
            callback({
              pagination: {
                pageIndex: 0,
                pageSize: pagination?.pageSize,
              },
              sorter: sorter?.order
                ? {
                    sortKey: sorter?.columnKey as unknown as string,
                    sort: sorter?.order,
                  }
                : null,
            });
            return;
          }
          callback({
            pagination: {
              pageIndex: pagination?.current,
              pageSize: pagination?.pageSize,
            },
            sorter: sorter?.order
              ? {
                  sortKey: sorter?.columnKey as unknown as string,
                  sort: sorter?.order,
                }
              : null,
          });
        }}
        pagination={
          pagination
            ? {
                total: pagination?.total,
                pageSize: pagination?.pageSize,
                current: pagination?.pageIndex + 1,
                showQuickJumper: true,
                showPrevNextJumpers: true,
                showSizeChanger: true,
                pageSizeOptions: [5, 10, 15, 20, 25, 30],
              }
            : false
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={tableColumns.map((column) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (sorter?.columnKey === column.key) {
            return {
              ...column,
              sortOrder: sorter?.order || null,
            };
          }
          return column;
        })}
        dataSource={data}
        scroll={scroll}
        rowKey="id"
        onRow={(record) => ({
          onDoubleClick: () => {
            setTaskIdToEdit(record.id);
          },
        })}
        rowSelection={
          noSelection
            ? null
            : {
                columnWidth: 50,
                selectedRowKeys: selectedIds,
                onChange: (_keys, records) =>
                  setSelectedIds(records.map((r) => r.id)),
                getCheckboxProps: (record: TaskIndexFieldsFragment) => ({
                  disabled:
                    record?.status === TaskStatus.Complete ||
                    record?.status === TaskStatus.Void,
                }),
                fixed: "left",
              }
        }
      />
      {modal}
      {taskType === TaskType.Operational ? (
        <OperationalTaskDetailModal
          callback={() => {
            queryAllTaskCallback();
          }}
          taskId={taskIdToEdit}
          onClose={() => {
            setTaskIdToEdit(null);
          }}
        />
      ) : (
        <TaskDetailModal
          columns={columns}
          taskId={taskIdToEdit}
          taskType={taskType}
          onClose={() => {
            setTaskIdToEdit(null);
          }}
          callback={() => {
            queryAllTaskCallback();
          }}
        />
      )}
    </>
  );
};

export default TaskTable;
