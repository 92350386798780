import { enumEnhancedTableWidthType } from "@/components/common/EnhancedTable/EnhancedTable";
import {
  ClassType,
  RegistrationStage,
  StudentBaseFieldsFragment,
} from "@/graphql";
import { enumToLabelArray } from "@/utils/enumUnit";
import { trim } from "lodash";
import moment from "moment";
import { ClassForValidate } from "./useClassListToValidate";

export const RegistrationStageMap: { [key: string]: RegistrationStage } = {
  Regular: RegistrationStage.Regular,
  regular: RegistrationStage.Regular,
  Reservation: RegistrationStage.Reservation,
  reservation: RegistrationStage.Reservation,
  Trial: RegistrationStage.Trial,
  trial: RegistrationStage.Trial,
  WaitingList: RegistrationStage.WaitingList,
  "Waiting List": RegistrationStage.WaitingList,
  "waiting list": RegistrationStage.WaitingList,
  "waiting List": RegistrationStage.WaitingList,
  waitinglist: RegistrationStage.WaitingList,
  waitingList: RegistrationStage.WaitingList,
};

export enum EnumImportRegistrationTempTitle {
  "EnrollmentType" = "Enrollment Type",
  "StudentName" = "Student Name",
  "StudentID" = "Student ID",
  "ClassCode" = "Class Code",
  "EnrollmentStartDate" = "Enrollment Start Date",
  "TrialDates" = "Trial Dates",
  "StartLessonNo" = "Start Lesson No.",
  "ReserveForTrial" = "Reserve for Trial",
}

export const CsvColumnTitles = [
  EnumImportRegistrationTempTitle.EnrollmentType,
  EnumImportRegistrationTempTitle.StudentName,
  EnumImportRegistrationTempTitle.StudentID,
  EnumImportRegistrationTempTitle.ClassCode,
  EnumImportRegistrationTempTitle.EnrollmentStartDate,
  EnumImportRegistrationTempTitle.TrialDates,
  EnumImportRegistrationTempTitle.StartLessonNo,
  EnumImportRegistrationTempTitle.ReserveForTrial,
];

export interface ImportRegistrationRecord {
  [EnumImportRegistrationTempTitle.EnrollmentType]: string;
  [EnumImportRegistrationTempTitle.StudentName]: string;
  [EnumImportRegistrationTempTitle.StudentID]: string;
  [EnumImportRegistrationTempTitle.ClassCode]: string;
  [EnumImportRegistrationTempTitle.EnrollmentStartDate]: string;
  [EnumImportRegistrationTempTitle.TrialDates]: string;
  [EnumImportRegistrationTempTitle.StartLessonNo]: string;
  [EnumImportRegistrationTempTitle.ReserveForTrial]: string;
  editingField?: EnumImportRegistrationTempTitle;
  rowKey?: string;
}

export const ImportRegistrationColumnMap = {
  [EnumImportRegistrationTempTitle.EnrollmentType]: {
    dataIndex: EnumImportRegistrationTempTitle.EnrollmentType,
    title: EnumImportRegistrationTempTitle.EnrollmentType,
    width: enumEnhancedTableWidthType.S_SMALL,
    render: (text: string, record: ImportRegistrationRecord) => {
      return text;
    },
  },
  [EnumImportRegistrationTempTitle.StudentID]: {
    dataIndex: EnumImportRegistrationTempTitle.StudentID,
    width: enumEnhancedTableWidthType.S_SMALL,
    title: EnumImportRegistrationTempTitle.StudentID,
    render: (text: string, record: ImportRegistrationRecord) => {
      return text;
    },
  },
  [EnumImportRegistrationTempTitle.StudentName]: {
    dataIndex: EnumImportRegistrationTempTitle.StudentName,
    width: enumEnhancedTableWidthType.S_SMALL,
    title: EnumImportRegistrationTempTitle.StudentName,
    render: (text: string, record: ImportRegistrationRecord) => {
      return text;
    },
  },
  [EnumImportRegistrationTempTitle.ClassCode]: {
    dataIndex: EnumImportRegistrationTempTitle.ClassCode,
    width: enumEnhancedTableWidthType.L_LARGE,
    title: EnumImportRegistrationTempTitle.ClassCode,
    render: (text: string, record: ImportRegistrationRecord) => {
      return text;
    },
  },
  [EnumImportRegistrationTempTitle.EnrollmentStartDate]: {
    dataIndex: EnumImportRegistrationTempTitle.EnrollmentStartDate,
    width: enumEnhancedTableWidthType.S_SMALL,
    title: EnumImportRegistrationTempTitle.EnrollmentStartDate,
    render: (text: string, record: ImportRegistrationRecord) => {
      return text;
    },
  },
  [EnumImportRegistrationTempTitle.TrialDates]: {
    dataIndex: EnumImportRegistrationTempTitle.TrialDates,
    width: enumEnhancedTableWidthType.S_SMALL,
    title: EnumImportRegistrationTempTitle.TrialDates,
    render: (text: string, record: ImportRegistrationRecord) => {
      return text;
    },
  },
  [EnumImportRegistrationTempTitle.StartLessonNo]: {
    dataIndex: EnumImportRegistrationTempTitle.StartLessonNo,
    width: enumEnhancedTableWidthType.S_SMALL,
    title: EnumImportRegistrationTempTitle.StartLessonNo,
    render: (text: string, record: ImportRegistrationRecord) => {
      return text;
    },
  },
  [EnumImportRegistrationTempTitle.ReserveForTrial]: {
    dataIndex: EnumImportRegistrationTempTitle.ReserveForTrial,
    width: enumEnhancedTableWidthType.S_SMALL,
    title: EnumImportRegistrationTempTitle.ReserveForTrial,
    render: (text: string, record: ImportRegistrationRecord) => {
      return text;
    },
  },
};

export interface ErrorCheckDependency {
  students: StudentBaseFieldsFragment[];
  classes: ClassForValidate[];
  record: ImportRegistrationRecord;
}

export const ValidImportRegistrationDateFormats: moment.MomentFormatSpecification =
  [
    "DD/MM/YYYY", // 28/05/2025
    "D/M/YYYY", // 28/5/2024
    "DD/MMM/YYYY", // 28/May/2024
    "D/MMM/YYYY", // 28/May/2024
    "DD-MMM-YYYY", // 28-May-2024
    "D-MMM-YYYY", // 28-May-2024
    "D-MM-YYYY", // 28-05-2024
    "D-M-YYYY", // 28-5-2024
  ];

const checkIsDateString = (value: string): boolean => {
  return moment(value, ValidImportRegistrationDateFormats, true).isValid();
};
export const getClassData = (data: ErrorCheckDependency) => {
  return data?.classes?.find(
    (item) =>
      trim(item.classCode) ===
      trim(data.record[EnumImportRegistrationTempTitle.ClassCode])
  );
};
const checkIsUnderLessonDate = (classData: ClassForValidate, value: string) => {
  return classData?.lessons?.some((item) => {
    const date = moment(trim(value), ValidImportRegistrationDateFormats, true);
    return date.isSame(moment(item.lessonStart), "date");
  });
};

const getEnrollmentTypeErrorMessage = (
  value: string,
  data?: ErrorCheckDependency
): string => {
  if (!trim(value)) {
    return "Field is required.";
  }
  const enrollmentType = RegistrationStageMap[value];
  if (
    !enumToLabelArray(RegistrationStage)
      .map((item) => item.value)
      .includes(enrollmentType)
  ) {
    return "Invalid Enrollment Type.";
  }
  return "";
};
const getStudentNameErrorMessage = (
  value: string,
  data?: ErrorCheckDependency
): string => {
  if (!trim(value)) {
    return "Field is required.";
  }
  if (!data?.students?.some((student) => trim(student.fullName) === value)) {
    return "Student not found.";
  }
  return "";
};

const getStudentIdErrorMessage = (
  value: string,
  data?: ErrorCheckDependency
): string => {
  if (!trim(value)) {
    return "Field is required.";
  }
  if (!data?.students?.some((student) => trim(student.userId) === value)) {
    return "Student ID not found.";
  }
  if (
    data?.students?.find((student) => student.userId === value)?.fullName !==
    data?.record?.["Student Name"]
  ) {
    return "Student ID and name do not match.";
  }
  return "";
};

const getClassCodeErrorMessage = (
  value: string,
  data?: ErrorCheckDependency
): string => {
  if (!trim(value)) {
    return "Field is required.";
  }

  if (!data?.classes?.some((item) => trim(item.classCode) === value)) {
    return "Class Code not found.";
  }

  return "";
};

const getEnrollmentStartDateErrorMessage = (
  value: string,
  data?: ErrorCheckDependency
): string => {
  const isTrialEnrollment =
    data?.record?.[EnumImportRegistrationTempTitle.EnrollmentType] ===
    RegistrationStage.Trial;
  if (isTrialEnrollment) {
    return "";
  }
  if (!trim(value)) {
    return "Field is required.";
  }
  if (!checkIsDateString(value)) {
    return "Invalid Start Date";
  }
  const classData = getClassData(data);
  const isUnderLessonDate = checkIsUnderLessonDate(classData, value);
  if (!isUnderLessonDate) {
    return "Invalid Start Date";
  }
  return "";
};

const getTrialDatesErrorMessage = (
  value: string,
  data?: ErrorCheckDependency
): string => {
  value = trim(value);
  const isTrialEnrollment =
    data?.record?.[EnumImportRegistrationTempTitle.EnrollmentType] ===
    RegistrationStage.Trial;
  if (!isTrialEnrollment) {
    return "";
  }
  if (!trim(value)) {
    return "Field is required.";
  }
  if (!value.split(",").map(trim).every(checkIsDateString)) {
    return "Invalid Trial Dates.";
  }

  const classData = getClassData(data);
  if (
    !value
      .split(",")
      .map(trim)
      .every((val) => checkIsUnderLessonDate(classData, val))
  ) {
    return "Invalid Trial Dates.";
  }

  return "";
};

const getStartLessonNoErrorMessage = (
  value: string,
  data?: ErrorCheckDependency
): string => {
  value = trim(value);
  const classData = getClassData(data);
  const isTrialEnrollment =
    data?.record?.[EnumImportRegistrationTempTitle.EnrollmentType] ===
    RegistrationStage.Trial;

  // check workshop class only
  if (classData?.type !== ClassType.Workshop) {
    return "";
  }

  if (isTrialEnrollment) {
    return "";
  }

  if (!trim(value)) {
    return "Field is required.";
  }

  // should be a positive number
  if (!/^Lesson [1-9]\d*$/.test(value)) {
    return "Invalid Lesson Number";
  }

  const isValidLessonNumber = classData?.lessons?.some((item) => {
    return `Lesson ${item?.lessonNumber}` === value;
  });
  if (!isValidLessonNumber) {
    return "Lesson Number not found.";
  }

  const matchedStartDate = moment(
    classData?.lessons?.find((item) => {
      return `${item.lessonNumber}` === value.replace("Lesson ", "");
    })?.lessonStart
  );
  const enrollmentStartDate = moment(
    data?.record[EnumImportRegistrationTempTitle.EnrollmentStartDate],
    ValidImportRegistrationDateFormats,
    true
  );
  const isLessonNumberMatchWithEnrollmentStartDate =
    matchedStartDate.isValid() &&
    enrollmentStartDate.isValid() &&
    matchedStartDate.isSame(enrollmentStartDate, "date");

  if (!isLessonNumberMatchWithEnrollmentStartDate) {
    return "Start lesson no. and enrollment start date do not match.";
  }
  return "";
};

export const getErrorByDataIndex = (
  value: string,
  dataIndex: EnumImportRegistrationTempTitle,

  data?: ErrorCheckDependency
): string => {
  if (dataIndex === EnumImportRegistrationTempTitle.EnrollmentType) {
    return getEnrollmentTypeErrorMessage(value, data);
  }
  if (dataIndex === EnumImportRegistrationTempTitle.StudentName) {
    return getStudentNameErrorMessage(value, data);
  }
  if (dataIndex === EnumImportRegistrationTempTitle.StudentID) {
    return getStudentIdErrorMessage(value, data);
  }
  if (dataIndex === EnumImportRegistrationTempTitle.ClassCode) {
    return getClassCodeErrorMessage(value, data);
  }
  if (dataIndex === EnumImportRegistrationTempTitle.EnrollmentStartDate) {
    return getEnrollmentStartDateErrorMessage(value, data);
  }
  if (dataIndex === EnumImportRegistrationTempTitle.TrialDates) {
    return getTrialDatesErrorMessage(value, data);
  }
  if (dataIndex === EnumImportRegistrationTempTitle.StartLessonNo) {
    return getStartLessonNoErrorMessage(value, data);
  }

  if (dataIndex === EnumImportRegistrationTempTitle.ReserveForTrial) {
    return getStartLessonNoErrorMessage(value, data);
  }

  return "";
};

export const checkIsRowValid = (
  record: ImportRegistrationRecord,
  data: ErrorCheckDependency
): boolean => {
  return [
    EnumImportRegistrationTempTitle.EnrollmentType,
    EnumImportRegistrationTempTitle.StudentName,
    EnumImportRegistrationTempTitle.StudentID,
    EnumImportRegistrationTempTitle.ClassCode,
    EnumImportRegistrationTempTitle.EnrollmentStartDate,
    EnumImportRegistrationTempTitle.TrialDates,
    EnumImportRegistrationTempTitle.StartLessonNo,
    EnumImportRegistrationTempTitle.ReserveForTrial,
  ].every(
    (dataIndex) => !getErrorByDataIndex(record[dataIndex], dataIndex, data)
  );
};
