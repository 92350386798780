import { ReactComponent as IconMenuClass } from "@/assets/images/IconMenuClass.svg";
import { ReactComponent as IconMenuClassArrangement } from "@/assets/images/IconMenuClassArrangement.svg";
import { ReactComponent as IconMenuCollapsedAcion } from "@/assets/images/IconMenuCollapsedAcion.svg";
import { ReactComponent as IconMenuCollapsedEnd } from "@/assets/images/IconMenuCollapsedEnd.svg";
import { ReactComponent as IconMenuCourse } from "@/assets/images/IconMenuCourse.svg";
import { ReactComponent as IconMenuDashboard } from "@/assets/images/IconMenuDashboard.svg";
import { ReactComponent as IconMenuFinance } from "@/assets/images/IconMenuFinance.svg";
import { ReactComponent as IconMenuParentCentre } from "@/assets/images/IconMenuParentCentre.svg";
import { ReactComponent as IconMenuSystem } from "@/assets/images/IconMenuSystem.svg";
import { ReactComponent as IconMenuTruck } from "@/assets/images/IconMenuTruck.svg";
import { ReactComponent as IconMenuUser } from "@/assets/images/IconMenuUser.svg";
import { ReactComponent as IconReport } from "@/assets/images/IconReport.svg";
import PiLabLogo from "@/assets/images/PiLabLogo.svg";
import logo from "@/assets/images/TPLLogo.svg";
import { EnumPermissions } from "@/components/authorize/Permissions";
import {
  KEYWORD_ENUM,
  useFeatureHook,
} from "@/components/settings/Features/utils";
import { AppConfig, IsProduction } from "@/config";
import { useAccessControl } from "@/hooks/useAccessControl";
import Icon from "@ant-design/icons";
import { Layout, Menu } from "@thepiquelab/archus-components-web";
import React, { useEffect, useState } from "react";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import "./SideBar.scss";
import UserContent from "./UserContent";

const { Sider } = Layout;
const { SubMenu } = Menu;

interface NavItemType {
  path: string;
  label: string;
  // eslint-disable-next-line no-undef
  icon?: JSX.Element;
  items?: Omit<NavItemType, "items">[];
  isExact?: boolean;
  hide?: boolean;
  keywords?: string;
}

export const showOrganizationLogo = (type: string): string => {
  switch (type) {
    case "TPL":
      return logo;
    case "Pilab":
      return PiLabLogo;
    default:
      return logo;
  }
};

const SideBar: React.FC = () => {
  const { pathname } = useLocation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [collapse, setCollapse] = useState<boolean>(false);
  const { hasAllPermissions, hasSomePermissions } = useAccessControl();

  const ClassArrangementTabNavItems: NavItemType[] = [
    {
      label: "Registrations",
      path: `/registration`,
      hide: !hasSomePermissions([EnumPermissions.REGISTRATION_VIEW]),
    },
    {
      label: "Replacement Lessons",
      path: `/makeup`,
      hide: !hasSomePermissions([EnumPermissions.ARRANGEMENT_VIEW]),
    },
    {
      label: "Transfers",
      path: `/transfer`,
      hide: !hasSomePermissions([EnumPermissions.ARRANGEMENT_VIEW]),
    },
    {
      label: "Additional Lessons",
      path: `/additional`,
      hide: !hasSomePermissions([EnumPermissions.ARRANGEMENT_VIEW]),
    },
    {
      label: "Withdrawals",
      path: `/withdraw`,
      hide: !hasSomePermissions([EnumPermissions.ARRANGEMENT_VIEW]),
    },
  ];

  const CustomersTabNavItems: NavItemType[] = [
    {
      path: `/parents`,
      label: "Parents/Guardians",
      hide: !hasAllPermissions([EnumPermissions.PARENT_VIEW]),
    },
    {
      path: `/students`,
      label: "Students",
      hide: !hasAllPermissions([EnumPermissions.STUDENT_VIEW]),
    },
  ];

  const SettingsNavItems: NavItemType[] = [
    {
      label: "Parameters",
      path: "/parameters",
      hide: !hasSomePermissions([
        EnumPermissions.DEPARTMENT_VIEW,
        EnumPermissions.BRANCH_VIEW,
        EnumPermissions.VENUE_VIEW,
        EnumPermissions.ACADEMIC_LEVEL_VIEW,
        EnumPermissions.ACADEMIC_YEAR_VIEW,
        EnumPermissions.SCHOOL_VIEW,
        EnumPermissions.GROUP_ASSIGNMENT_VIEW,
        EnumPermissions.TERM_BILLING_DUE_DATE_VIEW,
        EnumPermissions.HOLIDAY_VIEW,
        EnumPermissions.REFERENCE_DATA_UPDATE,
      ]),
    },
    {
      label: "Process Automation",
      path: "/automations",
      hide: !hasAllPermissions([EnumPermissions.WHATSAPP_ACCOUNT_VIEW]),
    },
    {
      label: "Promotion Exercise",
      path: "/promotion",
      hide: !hasAllPermissions([EnumPermissions.PROMOTION_VIEW]),
    },
    {
      label: "Rewards",
      path: "/rewards",
      hide:
        !useFeatureHook([KEYWORD_ENUM.global_rewards]) ||
        !hasAllPermissions([EnumPermissions.REWARD_VIEW]),
    },
    {
      label: "Integrations",
      path: "/integrations",
      hide: !hasAllPermissions([EnumPermissions.INTEGRATION_VIEW]),
    },
    {
      path: "/devices",
      label: "CSD Control",
      hide: !hasSomePermissions([EnumPermissions.DEVICE_VIEW]),
    },
    {
      label: "Features",
      path: "/features",
      hide: !hasAllPermissions([EnumPermissions.FEATURE_UPDATE]),
    },
  ];

  const CourseManagementTabNavItems: NavItemType[] = [
    {
      label: "Courses",
      path: `/course`,
      hide: !hasAllPermissions([EnumPermissions.COURSE_VIEW]),
    },
    {
      label: "Classes",
      path: `/class`,
      hide: !hasAllPermissions([EnumPermissions.CLASS_VIEW]),
    },
    {
      label: "Lessons",
      path: `/lessons`,
      hide: !hasAllPermissions([EnumPermissions.LESSON_VIEW]),
    },
    // { label: "Class Visibility", path: `/classVisibility` }
  ];

  const FinanceTabNavItems: NavItemType[] = [
    {
      label: "Invoices",
      path: `/invoices`,
      hide: !hasAllPermissions([EnumPermissions.INVOICE_VIEW]),
    },
    {
      label: "Receipts",
      path: `/receipts`,
      hide: !hasAllPermissions([EnumPermissions.RECEIPT_VIEW]),
    },
    // { label: "Credits", path: `/credits` },
    {
      label: "Refunds",
      path: `/refunds`,
      hide: !hasAllPermissions([EnumPermissions.REFUND_VIEW]),
    },
    {
      label: "Statement of Accounts",
      path: `/statements`,
      hide: !hasAllPermissions([EnumPermissions.STATEMENT_OF_ACCOUNT_VIEW]),
    },
    {
      label: "Xero Logs",
      path: `/xero-logs`,
      hide:
        IsProduction || !hasAllPermissions([EnumPermissions.INTEGRATION_VIEW]),
    },
  ];

  const ReportsTabNavItems: NavItemType[] = [
    {
      label: "Attendances",
      path: `/attendance`,
      hide: !hasAllPermissions([EnumPermissions.REPORT_ATTENDANCE_VIEW]),
    },
    {
      label: "Finance",
      path: `/finance`,
      hide: !hasSomePermissions([
        EnumPermissions.REPORT_INVOICE_VIEW,
        EnumPermissions.REPORT_PAYMENT_VIEW,
        EnumPermissions.REPORT_REVENUE_VIEW,
      ]),
    },
  ];

  const logisticsTabNavItems: NavItemType[] = [
    {
      label: "Materials",
      path: "/materials",
    },
    {
      label: "Delivery Centre",
      path: "/delivery-centre",
    },
  ];

  const parentCentreTabNavItems: NavItemType[] = [
    {
      label: "Account Requests",
      path: "/account-requests",
    },
    {
      label: "Arrangement Request",
      path: "/arrangement-requests",
    },
  ];

  const NavItems: NavItemType[] = [
    {
      path: "/",
      label: "Dashboard",
      icon: <Icon component={IconMenuDashboard} />,
      isExact: true,
      hide:
        !useFeatureHook([KEYWORD_ENUM.global_dashboard]) ||
        !hasAllPermissions([EnumPermissions.TASK_VIEW]),
    },
    {
      path: "/class-schedule",
      label: "Class Schedule",
      icon: <Icon component={IconMenuClass} />,
      isExact: true,
      hide: !hasSomePermissions([EnumPermissions.CLASS_VIEW]),
    },
    {
      path: "/course-management",
      label: "Course Management",
      icon: <Icon component={IconMenuCourse} />,
      items: CourseManagementTabNavItems,
      hide: !hasSomePermissions([
        EnumPermissions.COURSE_VIEW,
        EnumPermissions.CLASS_VIEW,
        EnumPermissions.LESSON_VIEW,
      ]),
    },
    {
      path: "/class-arrangement",
      label: "Class Arrangement",
      icon: <Icon component={IconMenuClassArrangement} />,
      items: ClassArrangementTabNavItems,
      hide: !hasSomePermissions([
        EnumPermissions.ARRANGEMENT_VIEW,
        EnumPermissions.REGISTRATION_VIEW,
      ]),
    },
    {
      path: "/finance",
      label: "Finance",
      icon: <Icon component={IconMenuFinance} />,
      items: FinanceTabNavItems,
      hide: !hasSomePermissions([
        EnumPermissions.INVOICE_VIEW,
        EnumPermissions.RECEIPT_VIEW,
        EnumPermissions.REFUND_VIEW,
        EnumPermissions.STATEMENT_OF_ACCOUNT_VIEW,
      ]),
    },
    {
      path: "/customers",
      label: "Customers",
      icon: <Icon component={IconMenuUser} />,
      items: CustomersTabNavItems,
      hide: !hasSomePermissions([
        EnumPermissions.PARENT_VIEW,
        EnumPermissions.STUDENT_VIEW,
      ]),
    },
    {
      path: "/system-user",
      label: "System User",
      icon: <Icon component={IconMenuUser} />,
      hide: !hasSomePermissions([EnumPermissions.SYSTEM_USER_VIEW]),
    },
    {
      path: "/settings",
      label: "System Settings",
      icon: <Icon component={IconMenuSystem} />,
      items: SettingsNavItems,
      hide: !hasSomePermissions([
        EnumPermissions.ACADEMIC_LEVEL_VIEW,
        EnumPermissions.ACADEMIC_YEAR_VIEW,
        EnumPermissions.DEPARTMENT_VIEW,
        EnumPermissions.BRANCH_VIEW,
        EnumPermissions.VENUE_VIEW,
        EnumPermissions.SCHOOL_VIEW,
        EnumPermissions.GROUP_ASSIGNMENT_VIEW,
        EnumPermissions.TERM_BILLING_DUE_DATE_VIEW,
        EnumPermissions.HOLIDAY_VIEW,
        EnumPermissions.INTEGRATION_VIEW,
        EnumPermissions.REFERENCE_DATA_UPDATE,
        EnumPermissions.DEVICE_VIEW,
      ]),
    },
    {
      path: "/reports",
      label: "Reports",
      icon: <Icon component={IconReport} />,
      items: ReportsTabNavItems,
      hide:
        !useFeatureHook([KEYWORD_ENUM.global_report]) ||
        !hasSomePermissions([
          EnumPermissions.REPORT_ATTENDANCE_VIEW,
          EnumPermissions.REPORT_INVOICE_VIEW,
          EnumPermissions.REPORT_PAYMENT_VIEW,
          EnumPermissions.REPORT_REVENUE_VIEW,
        ]),
    },
    {
      path: "/logistics",
      label: "Logistics",
      icon: <Icon component={IconMenuTruck} />,
      items: logisticsTabNavItems,
      hide:
        !useFeatureHook([KEYWORD_ENUM.global_logistics]) ||
        !hasSomePermissions([
          EnumPermissions.MATERIAL_VIEW,
          EnumPermissions.DELIVERY_VIEW,
        ]),
    },
    {
      path: "/parent-centre",
      label: "Parent Centre",
      icon: <Icon component={IconMenuParentCentre} />,
      items: parentCentreTabNavItems,
      hide:
        IsProduction ||
        !hasSomePermissions([
          EnumPermissions.USER_REQUEST_VIEW,
          EnumPermissions.ACCOUNT_REQUEST_VIEW,
        ]),
    },
  ];

  useEffect(() => {
    const openKeys: string[] = [];
    NavItems.flatMap((item) =>
      item.items
        ? [
            item,
            ...item.items.map((i) => ({
              ...i,
              path: `${item.path}${i.path}`,
            })),
          ]
        : item
    )
      .flat()
      .forEach((item) => {
        if (matchPath(pathname, { path: item.path, exact: !!item.isExact })) {
          openKeys.push(item.path);
        }
      });

    if (!openKeys.length) {
      openKeys.push("/");
    }

    setSelectedKeys(openKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onCollapse = (): void => {
    setCollapse(!collapse);
  };

  return (
    <Sider
      collapsible
      collapsed={collapse}
      theme="light"
      trigger={null}
      className="side-bar"
      width="310px"
    >
      <div className="flex flex-col h-inherit">
        <div
          className={`flex items-center pt-5 ${
            collapse ? "justify-center" : "px-5 justify-between"
          }`}
        >
          {!collapse && (
            <div className="w-1/2">
              <img
                src={showOrganizationLogo(AppConfig.OrganizationName)}
                alt="logo"
                className="object-contain h-14 w-auto"
              />
            </div>
          )}

          <div className="text-center">
            {collapse ? (
              <IconMenuCollapsedAcion
                fill="#9093AF"
                onClick={onCollapse}
                className="cursor-pointer w-6 h-6"
              />
            ) : (
              <IconMenuCollapsedEnd
                fill="#9093AF"
                onClick={onCollapse}
                className="cursor-pointer w-6 h-6"
              />
            )}
          </div>
        </div>

        <UserContent collapse={collapse} />

        <div className="flex-grow overflow-y-auto">
          <Menu theme="light" mode={"inline"} selectedKeys={selectedKeys}>
            {NavItems.filter((item) => !item.hide).map((item) =>
              item.items && item.items.length ? (
                <SubMenu
                  key={item.path}
                  icon={item.icon}
                  title={
                    <NavLink to={item.path} className="inline-block w-full">
                      <span
                        className={`${
                          collapse ? "" : "sideBar__menu_spanPadding"
                        }`}
                      >
                        {item.label}
                      </span>
                    </NavLink>
                  }
                >
                  {item.items
                    .filter((i) => !i.hide)
                    .map((submenuItem) => (
                      <Menu.Item
                        key={`${item.path}${submenuItem.path}`}
                        className={`${
                          selectedKeys.includes(
                            `${item.path}${submenuItem.path}`
                          )
                            ? "sideBar__menu_submenu_item--selected"
                            : ""
                        }`}
                      >
                        <NavLink to={`${item.path}${submenuItem.path}`}>
                          <span
                            className={`${
                              collapse ? "" : "sideBar__menu_spanPadding"
                            }`}
                          >
                            {submenuItem.label}
                          </span>
                        </NavLink>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : (
                <Menu.Item icon={item.icon} key={item.path}>
                  <NavLink to={item.path}>
                    <span
                      className={`${
                        collapse ? "" : "sideBar__menu_spanPadding"
                      }`}
                    >
                      {item.label}
                    </span>
                  </NavLink>
                </Menu.Item>
              )
            )}
          </Menu>
        </div>
      </div>
    </Sider>
  );
};

export default SideBar;
