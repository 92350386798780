export type Page<T> = {
  total: number;
  pageSize: number;
  pageIndex: number;
  items: T[];
};

export type PageQuery = {
  pageIndex?: number;
  pageSize?: number;
  sortBy?: string;
  desc?: boolean;
};

export enum CourseFeeType {
  Perlesson = "PERLESSON",
  Permonth = "PERMONTH",
  Perterm = "PERTERM",
  Perclass = "PERCLASS",
}

export enum DeviceStatus {
  Connected = "CONNECTED",
  Connecting = "CONNECTING",
  Disconnected = "DISCONNECTED",
}

export enum ClassType {
  Recurring = "RECURRING",
  Workshop = "WORKSHOP",
}

export enum ClassStatus {
  Incomplete = "INCOMPLETE",
  Created = "CREATED",
  Ongoing = "ONGOING",
  Completed = "COMPLETED",
  Void = "VOID",
}

export enum WeekDay {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
}

export enum LearningArrangementType {
  Onsite = "ONSITE",
  Digital = "DIGITAL",
  Hybrid = "HYBRID",
}

export enum CourseType {
  Regular = "REGULAR",
  Workshop = "WORKSHOP",
}
export enum CourseStatus {
  Created = "CREATED",
  Ongoing = "ONGOING",
  Completed = "COMPLETED",
  Terminated = "TERMINATED",
  Void = "VOID",
}

export enum UserRequestStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Rejected = "REJECTED",
}

export enum UserRequestType {
  Trial = "TRIAL",
  WaitingList = "WAITING_LIST",
  Regular = "REGULAR",
}

export enum UserRequestSource {
  website = "Website",
  app = "App",
}
export enum UserRequestSources {
  Website = "web",
  App = "app",
}

export enum EnrollmentType {
  Regular = "REGULAR",
  Trial = "TRIAL",
  Additional = "ADDITIONAL",
}

export enum EnrollmentStatus {
  Holding = "HOLDING",
  Active = "ACTIVE",
  Complete = "COMPLETE",
  Cancelled = "CANCELLED",
}

export enum RegistrationType {
  Regular = "REGULAR",
  Trial = "TRIAL",
}

export enum RegistrationStage {
  Regular = "REGULAR",
  Trial = "TRIAL",
  WaitingList = "WAITING_LIST",
  Reservation = "RESERVATION",
}

export enum RegistrationsStatus {
  Paid = "PAID",
  Complete = "COMPLETE",
  Expired = "EXPIRED",
  Waitlisted = "WAITLISTED",
  Cancelled = "CANCELLED",
  Reserved = "RESERVED",
  Draft = "DRAFT",
}

export enum EnrollmentGroupType {
  A = "A",
  B = "B",
  Unassigned = "UNASSIGNED",
}

export enum SubscriptionType {
  Registration = "REGISTRATION",
}

export enum PaymentMethod {
  Cash = "Cash",
  CreditCard = "Credit Card",
  BankTransfer = "Bank Transfer",
  ATMTransfer = "ATM Transfer",
  /**
   * @deprecated Use PayNow (Stripe)/PayNow (Bank) instead
   */
  PayNow = "Pay Now",
  PayNowStripe = "PayNow (Stripe)",
  PayNowBank = "PayNow (Bank)",
  DBSMax = "DBS MAX!",
  Cheque = "Cheque",
  Credit = "Credit",

  CreditCardStripe = "Credit Card (Stripe)",
  GrabPayStripe = "GrabPay (Stripe)",
}

export enum UserRoleType {
  Predefined = "PREDEFINED",
  Customized = "CUSTOMIZED",
}

export enum ReferenceDataCategory {
  AcademicStream = "academicStream",
  ExamType = "examType",
  Gender = "gender",
  RewardCategory = "rewardCategory",
  Salutation = "salutation",
  NameOfBank = "nameOfBank",
  PaymentProcessor = "paymentProcessor",
  Subject = "subject",
}

export enum EducationStage {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}
