import { CourseApi } from "@/apis/course/course.api";
import { FilterOptionAcademicLevelDTO } from "@/apis/course/types";
import { useRequest } from "ahooks";
import { useMemo } from "react";
import { OptionType } from "../types";

export const useLevelOptions = (): {
  loading: boolean;
  options: OptionType[];
  levels: FilterOptionAcademicLevelDTO[];
} => {
  const { loading, data: levels = [] } = useRequest(
    () => CourseApi.getAcademicLevelOptions(),
    {
      throwOnError: true,
      cacheKey: "level-options",
      staleTime: 5000,
    }
  );

  const options = useMemo(
    () =>
      levels.map((i) => ({
        label: i.name,
        value: i._id,
      })),
    [levels]
  );

  return {
    loading,
    options,
    levels,
  };
};
