import { getJsonFromCsvFile } from "@/components/class/ImportClasses/Config";
import { ClassType, RegistrationStage } from "@/graphql";
import { ModalButtonGroup } from "@thepiquelab/archus-components-web";
import { Modal, Progress, UploadFile, message } from "antd";
import { clone, isEmpty, trim } from "lodash";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import {
  EnumImportRegistrationTempTitle,
  ImportRegistrationRecord,
  RegistrationStageMap,
  ValidImportRegistrationDateFormats,
  getClassData,
} from "./Config";
import DataPreviewStep from "./DataPreviewStep/DataPreviewStep";
import GuideLineStep from "./GuideLineStep/GuideLineStep";
import UploadFileStep from "./UploadFileStep/UploadFileStep";
import { useBulkCreateRegistration } from "./hooks";
import { useClassListToValidate } from "./useClassListToValidate";
import { useStudentListToValidate } from "./useStudentListToValidate";
// import { useImportRegistrations } from "./hooks";

interface ImportRegistrationModalProps {
  visible: boolean;
  onClose: () => void;
}
enum ImportRegistrationStep {
  "GuideLine" = 0,
  "UploadFile" = 1,
  "DatePreview" = 2,
}
const PercentMap = [3, 50, 98];

const OkTextMap = {
  [ImportRegistrationStep.GuideLine]: "Next",
  [ImportRegistrationStep.UploadFile]: "Next",
  [ImportRegistrationStep.DatePreview]: "Import",
};
const CancelTextMap = {
  [ImportRegistrationStep.GuideLine]: "Cancel",
  [ImportRegistrationStep.UploadFile]: "Back",
  [ImportRegistrationStep.DatePreview]: "Back",
};

const ImportRegistrationModal: FC<ImportRegistrationModalProps> = (props) => {
  const { visible, onClose } = props;

  const [bulkCreateRegistration, { loading }] = useBulkCreateRegistration();

  const [validated, setValidated] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<ImportRegistrationRecord[]>(
    []
  );

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [registrationData, setRegistrationData] = useState<
    ImportRegistrationRecord[]
  >([]);
  const [step, setStep] = useState<ImportRegistrationStep>(
    ImportRegistrationStep.GuideLine
  );

  const {
    studentList,
    mergeStudentListUniq,
    queryStudents,
    queryInitialStudents,
    clearStudentList,
  } = useStudentListToValidate(registrationData);

  const {
    classList,
    mergeClassListUniq,
    queryClasses,
    queryInitialClasses,
    clearClassList,
  } = useClassListToValidate(registrationData);

  const resetState = (): void => {
    setFileList([]);
    setStep(ImportRegistrationStep.GuideLine);
    setRegistrationData([]);
    clearClassList();
    clearStudentList();
    setSelectedRows([]);
  };

  const onBulkCreateRegistration = async () => {
    if (!selectedRows?.length) {
      message.error("Please select at least one item!");
      return;
    }

    const validRecords = clone(selectedRows);
    const params = validRecords.map((record) => {
      const registrationStage =
        record[EnumImportRegistrationTempTitle.EnrollmentType];
      const enrollmentType = RegistrationStageMap[registrationStage];
      const startDateString =
        record[EnumImportRegistrationTempTitle.EnrollmentStartDate];
      const userId = record[EnumImportRegistrationTempTitle.StudentID];
      const reserveForTrial =
        record[EnumImportRegistrationTempTitle.ReserveForTrial];
      const startLessonNo = Number(
        trim(record[EnumImportRegistrationTempTitle.StartLessonNo]).replace(
          "Lesson ",
          ""
        )
      );
      const classData = getClassData({
        students: studentList,
        classes: classList,
        record,
      });
      const isWorkshop = classData.type === ClassType.Workshop;
      const isTrial = trim(enrollmentType) === RegistrationStage.Trial;
      const trialDates = record[EnumImportRegistrationTempTitle.TrialDates]
        .split(",")
        .map(trim)
        .map((dateString) => {
          return moment(dateString, ValidImportRegistrationDateFormats);
        })
        .sort((a, b) => (a.isBefore(b) ? -1 : 1));
      return {
        stage: trim(enrollmentType) as RegistrationStage,
        startDate: isTrial
          ? trialDates[0].toISOString()
          : moment(startDateString, ValidImportRegistrationDateFormats)
              ?.startOf("day")
              ?.toISOString(),
        classId: classData.id,
        studentId: studentList.find(
          (student) => student.userId === trim(userId)
        ).id,
        reserveForTrial: trim(reserveForTrial) === "Y",
        // for workshop or trial
        lessonIds: isWorkshop
          ? classData?.lessons
              ?.filter((lesson) => lesson.lessonNumber >= startLessonNo)
              ?.map((lesson) => lesson.id)
          : isTrial
          ? classData?.lessons
              .filter((lesson) =>
                trialDates.some((trialDate) =>
                  trialDate.isSame(moment(lesson.lessonStart), "date")
                )
              )
              .map((lesson) => lesson.id)
          : undefined,
      };
    });
    await bulkCreateRegistration({ variables: { input: { details: params } } });
    resetState();
    onClose();
  };

  const onOk = async (): Promise<void> => {
    if (step === ImportRegistrationStep.GuideLine) {
      setStep(ImportRegistrationStep.UploadFile);
    }
    if (step === ImportRegistrationStep.UploadFile) {
      setStep(ImportRegistrationStep.DatePreview);
      queryInitialStudents();
      queryInitialClasses();
    }
    if (step === ImportRegistrationStep.DatePreview) {
      onBulkCreateRegistration();
    }
  };
  const onCancel = (): void => {
    if (step === ImportRegistrationStep.GuideLine) {
      resetState();
      onClose();
    }
    if (step === ImportRegistrationStep.UploadFile) {
      resetState();
    }
    if (step === ImportRegistrationStep.DatePreview) {
      resetState();
      setStep(ImportRegistrationStep.UploadFile);
    }
  };
  useEffect(() => {
    const getClassData = async (): Promise<void> => {
      if (!fileList.length) {
        setRegistrationData([]);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const result = await getJsonFromCsvFile(fileList[0]);

      const isAllValuesEmpty = (obj: ImportRegistrationRecord): boolean =>
        Object.values(obj).every((value) => isEmpty(value));
      const registrationData = (
        (result.data || []) as ImportRegistrationRecord[]
      ).filter((registration) => !isAllValuesEmpty(registration));

      setRegistrationData(
        registrationData.map((item, index: number) => ({
          ...item,
          rowKey: `${index}`,
        }))
      );
    };
    getClassData();
  }, [fileList]);
  return (
    <Modal
      visible={visible}
      width="1000px"
      title="Import Registration"
      onCancel={() => {
        resetState();
        onClose();
      }}
      destroyOnClose
      footer={
        <ModalButtonGroup
          showOk
          showCancel
          okText={OkTextMap[step]}
          cancelText={CancelTextMap[step]}
          onCancel={onCancel}
          onOK={onOk}
          confirmLoading={loading}
          okButtonProps={{
            disabled:
              (step === ImportRegistrationStep.UploadFile &&
                !fileList?.length) ||
              validated,
          }}
        />
      }
    >
      <div className="flex justify-between font-semibold">
        <div>Guideline</div>
        <div>Upload File</div>
        <div>Data Preview</div>
      </div>
      <Progress percent={PercentMap[step]} showInfo={false} />
      <div className="mt-6">
        {step === ImportRegistrationStep.GuideLine && <GuideLineStep />}
        {step === ImportRegistrationStep.UploadFile && (
          <UploadFileStep
            fileList={fileList}
            setFileList={setFileList}
            onValidation={setValidated}
          />
        )}
        {step === ImportRegistrationStep.DatePreview && (
          <DataPreviewStep
            dataSource={registrationData}
            setRegistrationData={setRegistrationData}
            mergeStudentListUniq={mergeStudentListUniq}
            studentList={studentList}
            queryStudents={queryStudents}
            classList={classList}
            queryClasses={queryClasses}
            mergeClassListUniq={mergeClassListUniq}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        )}
      </div>
    </Modal>
  );
};

export default ImportRegistrationModal;
