import { RegistrationStage } from "@/apis/types";

export const setSessionRegistrationStage = (type: RegistrationStage): void => {
  if (sessionStorage)
    sessionStorage.setItem("RegistrationType", type.toString());
};
export const getSessionRegistrationStage = (): RegistrationStage =>
  (sessionStorage.getItem("RegistrationType") as RegistrationStage) ||
  RegistrationStage.Regular;

export const removeSessionRegistrationType = (): void => {
  if (sessionStorage.getItem("RegistrationType"))
    sessionStorage.removeItem("RegistrationType");
};
